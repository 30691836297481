import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  LockClosedIcon, 
  ShieldCheckIcon, 
  DocumentCheckIcon,
  UserGroupIcon 
} from '@heroicons/react/24/outline';
import WordFadeIn from './WordFadeIn';

interface PrivacyFeature {
  icon: React.ReactElement;
  title: string;
  description: string;
}

const PrivacyCard: React.FC<PrivacyFeature> = ({ icon, title, description }) => (
  <div className="relative group h-full">
    {/* Glow effect on hover */}
    <div className="absolute inset-0 bg-gradient-to-r from-[#00ff9d] to-[#00ffff] opacity-0 group-hover:opacity-20 blur-xl transition-opacity duration-500" />
    
    <div className="relative h-full bg-[rgba(26,26,46,0.7)] backdrop-blur-md p-8 rounded-lg border border-[rgba(0,255,157,0.2)] transition-all duration-300 hover:border-[#00ff9d] hover:shadow-[0_0_20px_rgba(0,255,157,0.3)] hover:-translate-y-1">
      {/* Icon container - reduced size and centered */}
      <div className="relative flex justify-center mb-6">
        <div className="relative h-12 w-12 rounded-lg bg-[rgba(0,255,157,0.1)] border border-[#00ff9d] flex items-center justify-center">
          {React.cloneElement(icon, { 
            className: "h-6 w-6 text-[#00ff9d] drop-shadow-[0_0_8px_rgba(0,255,157,0.3)]" 
          })}
        </div>
      </div>

      <h3 className="text-xl font-semibold text-white mb-4 drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]">
        {title}
      </h3>
      <p className="text-gray-300">
        {description}
      </p>

      {/* Decorative corner elements */}
      <div className="absolute top-0 left-0 w-8 h-8 border-t-2 border-l-2 border-[#00ff9d] rounded-tl-lg opacity-50" />
      <div className="absolute top-0 right-0 w-8 h-8 border-t-2 border-r-2 border-[#00ff9d] rounded-tr-lg opacity-50" />
      <div className="absolute bottom-0 left-0 w-8 h-8 border-b-2 border-l-2 border-[#00ff9d] rounded-bl-lg opacity-50" />
      <div className="absolute bottom-0 right-0 w-8 h-8 border-b-2 border-r-2 border-[#00ff9d] rounded-br-lg opacity-50" />
    </div>
  </div>
);

const SectionDataPrivacy: React.FC = () => {
  const { t } = useTranslation();

  const features: PrivacyFeature[] = [
    {
      icon: <LockClosedIcon />,
      title: t('sectionDataPrivacy.dataProtection.title'),
      description: t('sectionDataPrivacy.dataProtection.description')
    },
    {
      icon: <ShieldCheckIcon />,
      title: t('sectionDataPrivacy.accessControls.title'),
      description: t('sectionDataPrivacy.accessControls.description')
    },
    {
      icon: <DocumentCheckIcon />,
      title: t('sectionDataPrivacy.compliance.title'),
      description: t('sectionDataPrivacy.compliance.description')
    },
    {
      icon: <UserGroupIcon />,
      title: t('sectionDataPrivacy.userRights.title'),
      description: t('sectionDataPrivacy.userRights.description')
    }
  ];

  return (
    <section className="relative overflow-hidden py-20" style={{ background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)' }}>
      {/* Background Effects */}
      <div className="absolute -top-24 -left-24 w-96 h-96 bg-[#00ff9d] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />
      <div className="absolute -bottom-24 -right-24 w-96 h-96 bg-[#00ffff] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />

      {/* Animated security grid background */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0" style={{
          backgroundImage: `
            radial-gradient(circle at 50% 50%, rgba(0,255,157,0.1) 0%, transparent 50%),
            linear-gradient(rgba(0,255,157,0.05) 2px, transparent 2px),
            linear-gradient(90deg, rgba(0,255,157,0.05) 2px, transparent 2px)
          `,
          backgroundSize: 'cover, 30px 30px, 30px 30px',
          animation: 'grid-move 20s linear infinite'
        }} />
      </div>

      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <WordFadeIn 
            words={t('sectionDataPrivacy.title')}
            className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-6 drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]"
          />
          <p className="text-xl text-gray-300 max-w-3xl mx-auto drop-shadow-[0_0_8px_rgba(255,255,255,0.1)]">
            {t('sectionDataPrivacy.subtitle')}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 auto-rows-fr">
          {features.map((feature, index) => (
            <PrivacyCard key={index} {...feature} />
          ))}
        </div>
      </div>

      {/* Add the grid animation */}
      <style>{`
        @keyframes grid-move {
          0% {
            background-position: 0 0, 0 0, 0 0;
          }
          100% {
            background-position: 0 0, 30px 30px, 30px 30px;
          }
        }

        .clip-hex {
          clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        }
      `}</style>
    </section>
  );
};

export default SectionDataPrivacy;

export {};
