import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import WordFadeIn from './WordFadeIn';
import { ChatBubbleLeftRightIcon, TruckIcon, BuildingStorefrontIcon, EnvelopeIcon, DevicePhoneMobileIcon } from '@heroicons/react/24/outline';
import { AnimatedBeam } from "./AnimatedBeam";
import CircleHero from "./CircleHero";

const FeatureCard: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({ icon, title, description }) => (
  <div className="bg-[rgba(26,26,46,0.7)] backdrop-blur-md p-6 rounded-lg border border-[rgba(0,255,157,0.2)] transition-all duration-300 hover:border-[#00ff9d] hover:shadow-[0_0_20px_rgba(0,255,157,0.3)] hover:-translate-y-1">
    <div className="flex items-center mb-4">
      <div className="mr-4 text-[#00ff9d] drop-shadow-[0_0_8px_rgba(0,255,157,0.3)]">
        {React.cloneElement(icon as React.ReactElement, { className: "h-8 w-8" })}
      </div>
      <h3 className="text-xl font-semibold text-white drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]">{title}</h3>
    </div>
    <p className="text-gray-300">{description}</p>
  </div>
);

const AnimatedBeamCentralization: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const div1Ref = useRef<HTMLDivElement>(null);
  const div2Ref = useRef<HTMLDivElement>(null);
  const div3Ref = useRef<HTMLDivElement>(null);
  const div4Ref = useRef<HTMLDivElement>(null);
  const div5Ref = useRef<HTMLDivElement>(null);
  const centerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className="relative flex h-[500px] w-full max-w-[50rem] items-center justify-center overflow-hidden rounded-lg p-16 bg-[rgba(26,26,46,0.7)] backdrop-blur-md border border-[rgba(0,255,157,0.2)] transition-all duration-300 hover:border-[#00ff9d] hover:shadow-[0_0_20px_rgba(0,255,157,0.3)]"
      ref={containerRef}
    >
      <div className="flex h-full w-full flex-row items-stretch justify-between gap-10">
        <div className="flex flex-col justify-center gap-4">
          <CircleHero ref={div1Ref} className="h-16 w-16 bg-[#1a1a2e] border border-[#00ff9d] p-3 transition-all duration-300 hover:shadow-[0_0_20px_rgba(0,255,157,0.3)]">
            <ChatBubbleLeftRightIcon className="h-full w-full text-[#00ff9d]" />
          </CircleHero>
          <CircleHero ref={div2Ref} className="h-16 w-16 bg-[#1a1a2e] border border-[#00ff9d] p-3 transition-all duration-300 hover:shadow-[0_0_20px_rgba(0,255,157,0.3)]">
            <TruckIcon className="h-full w-full text-[#00ff9d]" />
          </CircleHero>
          <CircleHero ref={div3Ref} className="h-16 w-16 bg-[#1a1a2e] border border-[#00ff9d] p-3 transition-all duration-300 hover:shadow-[0_0_20px_rgba(0,255,157,0.3)]">
            <BuildingStorefrontIcon className="h-full w-full text-[#00ff9d]" />
          </CircleHero>
          <CircleHero ref={div4Ref} className="h-16 w-16 bg-[#1a1a2e] border border-[#00ff9d] p-3 transition-all duration-300 hover:shadow-[0_0_20px_rgba(0,255,157,0.3)]">
            <EnvelopeIcon className="h-full w-full text-[#00ff9d]" />
          </CircleHero>
          <CircleHero ref={div5Ref} className="h-16 w-16 bg-[#1a1a2e] border border-[#00ff9d] p-3 transition-all duration-300 hover:shadow-[0_0_20px_rgba(0,255,157,0.3)]">
            <DevicePhoneMobileIcon className="h-full w-full text-[#00ff9d]" />
          </CircleHero>
        </div>
        <div className="flex flex-col justify-center">
          <CircleHero ref={centerRef} className="h-40 w-40 bg-[#1a1a2e] border-2 border-[#00ff9d] p-4 shadow-[0_0_30px_rgba(0,255,157,0.3)]">
            <img src="/lobow.webp" alt="Unanimity AI Logo" className="h-28 w-28 object-contain" />
          </CircleHero>
        </div>
      </div>
      <AnimatedBeam containerRef={containerRef} fromRef={div1Ref} toRef={centerRef} color="#00ff9d" />
      <AnimatedBeam containerRef={containerRef} fromRef={div2Ref} toRef={centerRef} color="#00ff9d" />
      <AnimatedBeam containerRef={containerRef} fromRef={div3Ref} toRef={centerRef} color="#00ff9d" />
      <AnimatedBeam containerRef={containerRef} fromRef={div4Ref} toRef={centerRef} color="#00ff9d" />
      <AnimatedBeam containerRef={containerRef} fromRef={div5Ref} toRef={centerRef} color="#00ff9d" />
    </div>
  );
};

const SectionCentralize: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="relative overflow-hidden py-20" style={{ background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)' }}>
      {/* Background glow effects */}
      <div className="absolute -top-24 -left-24 w-96 h-96 bg-[#00ff9d] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />
      <div className="absolute -bottom-24 -right-24 w-96 h-96 bg-[#00ffff] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />

      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="relative text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
            <span className="relative z-10">
              <WordFadeIn 
                words={t('sectionCentralize.title')} 
                className="!text-[#00ff9d] !drop-shadow-[0_0_8px_rgba(0,255,157,0.3)]"
              />
            </span>
            {/* Title glow effect */}
            <span className="absolute inset-0 blur-[2px] opacity-50 z-0">
              <WordFadeIn 
                words={t('sectionCentralize.title')} 
                className="!text-[#00ff9d]"
              />
            </span>
          </h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto drop-shadow-[0_0_8px_rgba(255,255,255,0.1)]">
            {t('sectionCentralize.subtitle')}
          </p>
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-between gap-12 mb-16">
          <div className="w-full lg:w-1/2">
            <div className="relative p-8">
              <div
                className="absolute inset-0 -z-10 blur-[40px]"
                style={{
                  background: 'radial-gradient(circle at 50% 50%, rgba(0,255,157,0.2) 0%, transparent 70%)'
                }}
              />
              <AnimatedBeamCentralization />
            </div>
          </div>
          <div className="w-full lg:w-1/2 space-y-8">
            <FeatureCard
              icon={<ChatBubbleLeftRightIcon />}
              title={t('sectionCentralize.features.0.title')}
              description={t('sectionCentralize.features.0.description')}
            />
            <FeatureCard
              icon={<TruckIcon />}
              title={t('sectionCentralize.features.1.title')}
              description={t('sectionCentralize.features.1.description')}
            />
            <FeatureCard
              icon={<BuildingStorefrontIcon />}
              title={t('sectionCentralize.features.2.title')}
              description={t('sectionCentralize.features.2.description')}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionCentralize;