import React from "react";
import { useTranslation } from 'react-i18next';
import WordFadeIn from "./WordFadeIn";
import { 
  UserGroupIcon, 
  ClipboardDocumentListIcon, 
  ArrowPathRoundedSquareIcon, 
  ChatBubbleLeftRightIcon, 
  ChartBarIcon, 
  ArrowsPointingInIcon, 
  GlobeAltIcon, 
  AdjustmentsHorizontalIcon 
} from '@heroicons/react/24/outline';

interface Feature {
  title: string;
  description: string;
  icon: React.ReactElement;
}

const FeatureCard: React.FC<Feature> = ({ title, description, icon }) => (
  <div className="relative group">
    {/* Glow effect on hover */}
    <div className="absolute inset-0 bg-gradient-to-r from-[#00ff9d] to-[#00ffff] opacity-0 group-hover:opacity-20 blur-xl transition-opacity duration-500" />
    
    <div className="relative bg-[rgba(26,26,46,0.7)] backdrop-blur-md p-6 rounded-lg border border-[rgba(0,255,157,0.2)] transition-all duration-300 hover:border-[#00ff9d] hover:shadow-[0_0_20px_rgba(0,255,157,0.3)] hover:-translate-y-1">
      <div className="mb-4">
        {React.cloneElement(icon, { 
          className: "w-12 h-12 text-[#00ff9d] drop-shadow-[0_0_8px_rgba(0,255,157,0.3)]" 
        })}
      </div>
      <h3 className="text-lg font-semibold mb-2 text-white drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]">
        {title}
      </h3>
      <p className="text-gray-300">{description}</p>
    </div>
  </div>
);

const SectionFeature: React.FC = () => {
  const { t } = useTranslation();

  const icons = [
    <UserGroupIcon />,
    <ClipboardDocumentListIcon />,
    <ArrowPathRoundedSquareIcon />,
    <ChatBubbleLeftRightIcon />,
    <ChartBarIcon />,
    <ArrowsPointingInIcon />,
    <GlobeAltIcon />,
    <AdjustmentsHorizontalIcon />
  ];

  const features: Feature[] = (t('sectionFeature.features', { returnObjects: true }) as any[]).map((feature, index) => ({
    title: feature.title,
    description: feature.description,
    icon: icons[index],
  }));

  return (
    <section className="relative overflow-hidden py-20" style={{ background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)' }}>
      {/* Animated grid background */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0" style={{
          backgroundImage: `
            linear-gradient(to right, rgba(0,255,157,0.1) 1px, transparent 1px),
            linear-gradient(to bottom, rgba(0,255,157,0.1) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px',
          maskImage: 'radial-gradient(circle at 50% 50%, black, transparent)',
        }} />
      </div>

      {/* Glow effects */}
      <div className="absolute -top-24 -left-24 w-96 h-96 bg-[#00ff9d] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />
      <div className="absolute -bottom-24 -right-24 w-96 h-96 bg-[#00ffff] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />

      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <WordFadeIn
            words={t('sectionFeature.title')}
            className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-6 drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]"
          />
          <p className="text-xl md:text-2xl text-gray-300 max-w-4xl mx-auto drop-shadow-[0_0_8px_rgba(255,255,255,0.1)]">
            {t('sectionFeature.description')}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>

        <div className="mt-20 text-center">
          <h3 className="text-2xl font-semibold mb-6 text-white drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]">
            {t('sectionFeature.showcase.title')}
          </h3>
          <div className="relative">
            {/* Image stack container */}
            <div className="flex justify-center items-center relative h-[600px]">
              {['/chat.webp', '/stats2.webp', '/socialmedia.webp', '/stats1.webp'].map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Unanimity AI Feature ${index + 1}`}
                  className="absolute w-[400px] h-[300px] object-cover rounded-lg border-4 border-white/10 shadow-xl"
                  style={{
                    transform: `translate(${index * 80 - 160}px, ${index * 20 - 40}px) rotate(${index * 3 - 4}deg)`,
                    zIndex: index,
                  }}
                />
              ))}
            </div>
          </div>

          <a 
            href="/get-in-touch" 
            className="inline-flex items-center mt-16 px-8 py-3 text-base font-semibold uppercase tracking-wider text-[#00ff9d] border border-[#00ff9d] rounded-full transition-all duration-300 hover:bg-[rgba(0,255,157,0.1)] hover:shadow-[0_0_20px_rgba(0,255,157,0.3)] hover:scale-105"
          >
            {t('sectionFeature.showcase.cta')}
          </a>
        </div>
      </div>
    </section>
  );
};

export default SectionFeature;