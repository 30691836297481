import React from "react";
import { useTranslation } from 'react-i18next';
import WordFadeIn from "./WordFadeIn";
import { 
  ArrowPathIcon, 
  UserGroupIcon, 
  ChatBubbleLeftRightIcon,
  ArrowDownIcon 
} from '@heroicons/react/24/outline';

const RoutingStep: React.FC<{ icon: React.ReactNode, title: string, description: string }> = ({ icon, title, description }) => (
  <div className="flex items-start mb-8 p-6 bg-[rgba(26,26,46,0.7)] backdrop-blur-md rounded-lg border border-[rgba(0,255,157,0.2)] transition-all duration-300 hover:border-[#00ff9d] hover:shadow-[0_0_20px_rgba(0,255,157,0.3)] hover:-translate-y-1">
    <div className="flex-shrink-0 mr-4">
      <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-[rgba(0,255,157,0.1)] text-[#00ff9d] border border-[#00ff9d] shadow-[0_0_15px_rgba(0,255,157,0.3)]">
        {React.cloneElement(icon as React.ReactElement, { className: "h-6 w-6" })}
      </div>
    </div>
    <div>
      <h3 className="text-lg font-medium text-white mb-2 drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]">{title}</h3>
      <p className="text-gray-300">{description}</p>
    </div>
  </div>
);

const ProcessVisualization: React.FC = () => (
  <div className="relative w-full bg-[rgba(26,26,46,0.7)] backdrop-blur-md rounded-xl border border-[rgba(0,255,157,0.2)] p-8">
    {/* Simple linear flow visualization */}
    <div className="relative flex flex-col items-center space-y-8">
      {/* Customer Input */}
      <div className="relative w-full max-w-sm bg-[rgba(0,255,157,0.1)] p-6 rounded-lg border border-[#00ff9d] shadow-[0_0_20px_rgba(0,255,157,0.3)]">
        <div className="flex items-center space-x-4">
          <ChatBubbleLeftRightIcon className="w-8 h-8 text-[#00ff9d]" />
          <div>
            <h3 className="text-white font-medium">Customer Request</h3>
            <p className="text-sm text-gray-300">Inquiry, return, or support</p>
          </div>
        </div>
      </div>

      {/* Arrow */}
      <ArrowDownIcon className="w-6 h-6 text-[#00ff9d] animate-bounce" />

      {/* AI Analysis */}
      <div className="relative w-full max-w-sm bg-[rgba(0,255,157,0.1)] p-6 rounded-lg border border-[#00ff9d] shadow-[0_0_20px_rgba(0,255,157,0.3)]">
        <div className="flex items-center space-x-4">
          <img src="/logo192.webp" alt="AI" className="w-8 h-8" />
          <div>
            <h3 className="text-white font-medium">AI Analysis</h3>
            <p className="text-sm text-gray-300">Automatic request processing</p>
          </div>
        </div>
      </div>

      {/* Arrow with split */}
      <div className="relative w-full flex items-center justify-center">
        <div className="absolute w-px h-16 bg-gradient-to-b from-[#00ff9d] to-transparent" />
        <div className="absolute w-48 h-px bg-gradient-to-r from-transparent via-[#00ff9d] to-transparent top-16" />
      </div>

      {/* Outcomes */}
      <div className="flex justify-between w-full max-w-lg">
        {/* Automated Resolution */}
        <div className="w-[45%] bg-[rgba(0,255,157,0.1)] p-4 rounded-lg border border-[#00ff9d] shadow-[0_0_20px_rgba(0,255,157,0.3)]">
          <div className="flex flex-col items-center text-center">
            <ArrowPathIcon className="w-6 h-6 text-[#00ff9d] mb-2" />
            <h3 className="text-white font-medium text-sm">Automated Resolution</h3>
            <p className="text-xs text-gray-300">Simple cases</p>
          </div>
        </div>

        {/* Human Support */}
        <div className="w-[45%] bg-[rgba(0,255,157,0.1)] p-4 rounded-lg border border-[#00ff9d] shadow-[0_0_20px_rgba(0,255,157,0.3)]">
          <div className="flex flex-col items-center text-center">
            <UserGroupIcon className="w-6 h-6 text-[#00ff9d] mb-2" />
            <h3 className="text-white font-medium text-sm">Human Support</h3>
            <p className="text-xs text-gray-300">Complex cases</p>
          </div>
        </div>
      </div>
    </div>

    {/* Subtle grid background */}
    <div className="absolute inset-0 -z-10 opacity-5">
      <div className="w-full h-full" style={{
        backgroundImage: 'linear-gradient(#00ff9d 1px, transparent 1px), linear-gradient(90deg, #00ff9d 1px, transparent 1px)',
        backgroundSize: '20px 20px'
      }} />
    </div>
  </div>
);

const SectionEmployee: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="relative overflow-hidden py-20" style={{ background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)' }}>
      {/* Background Effects */}
      <div className="absolute -top-24 -left-24 w-96 h-96 bg-[#00ff9d] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />
      <div className="absolute -bottom-24 -right-24 w-96 h-96 bg-[#00ffff] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />

      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row items-center justify-between gap-16">
          {/* Text Content */}
          <div className="w-full lg:w-1/2">
            <WordFadeIn 
              words={t('sectionEmployee.title')}
              className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-6 drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]"
            />
            <p className="text-xl text-gray-300 mb-8 drop-shadow-[0_0_8px_rgba(255,255,255,0.1)]">
              {t('sectionEmployee.description')}
            </p>
            <div className="space-y-6">
              <RoutingStep
                icon={<ArrowPathIcon />}
                title={t('sectionEmployee.features.0.title')}
                description={t('sectionEmployee.features.0.description')}
              />
              <RoutingStep
                icon={<UserGroupIcon />}
                title={t('sectionEmployee.features.1.title')}
                description={t('sectionEmployee.features.1.description')}
              />
              <RoutingStep
                icon={<ChatBubbleLeftRightIcon />}
                title={t('sectionEmployee.features.2.title')}
                description={t('sectionEmployee.features.2.description')}
              />
            </div>
          </div>

          {/* Process Graph */}
          <div className="w-full lg:w-1/2">
            <ProcessVisualization />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionEmployee;