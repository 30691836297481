"use client";

import React, { useState } from 'react';
import { motion } from "framer-motion";
import { Switch } from "./ui/switch";
import { CheckIcon } from "@radix-ui/react-icons";
import WordFadeIn from "./WordFadeIn";

/*
  Utility to convert integer price (in cents) to human-readable euro format.
  E.g. 249900 => "2499"
*/
function toHumanPrice(priceInCents: number) {
  return (priceInCents / 100).toFixed(0);
}

/*
  Each plan has:
  - name
  - baseCoverage: how many tickets are included without extra cost
  - monthlyPrice: base monthly cost in cents (if within coverage)
  - annualPrice: discounted monthly cost in cents if user toggles "annual" (-20% for example)
  - extraTicketCost: cost in cents for each ticket above baseCoverage
  - maxThreshold: if user volume > maxThreshold, we jump to next plan
*/
type PlanData = {
  name: string;
  description: string;
  baseCoverage: number;
  monthlyPrice: number | null;
  annualPrice: number | null;
  extraTicketCost: number;  // cost in cents per extra ticket
  maxThreshold: number | null; 
  features: string[];
};

const PLANS: PlanData[] = [
  {
    name: "Essentiel",
    description: "Pour ~500 tickets couverts, extra cost jusqu'à 2 000",
    baseCoverage: 500,
    monthlyPrice: 109900, // 399€
    annualPrice: 101920,  // 319.2€ (20% discount)
    extraTicketCost: 75, // 1.25€ in cents
    maxThreshold: 1999, // At 2,001, jump to next plan
    features: [
      "SAV externalisé à 100% (retours, remboursements, colis perdus...)",
      "Disponibilité 24/7 via IA + équipe humaine",
      "Portail simplifié pour suivre résolutions",
      "Audit mensuel basique"
    ]
  },
  {
    name: "Croissance",
    description: "2 000 tickets couverts, extra cost jusqu'à 5 000",
    baseCoverage: 2000,
    monthlyPrice: 199900, // 799€
    annualPrice: 193920,  // 639.2€
    extraTicketCost: 50, // 1.00€ in cents
    maxThreshold: 4999, // At 5,001, jump to next plan
    features: [
      "Temps de réponse prioritaire (SLA amélioré)",
      "Suivi transporteurs & litiges complexes",
      "Portail de monitoring en temps réel + rapports mensuels",
      "IA adaptée à vos FAQs & procédures internes"
    ]
  },
  {
    name: "Scale",
    description: "5 000 tickets couverts, extra cost jusqu'à 20 000",
    baseCoverage: 5000,
    monthlyPrice: 339900, // 2 499€
    annualPrice: 329920,  // 1 999.20€
    extraTicketCost: 25, // 0.75€ in cents
    maxThreshold: 5001,  // at 20,001 => jump to Entreprise
    features: [
      "Analyse avancée (sentiment, récurrence...)",
      "Équipe dédiée pour personnalisation du SAV",
      "Audit mensuel complet + conseils d'optimisation",
      "Mises à jour régulières du knowledge base"
    ]
  },
  {
    name: "Entreprise",
    description: "L'offre ultime (tickets illimités)",
    baseCoverage: 999999,  // effectively unlimited
    monthlyPrice: null,
    annualPrice: null,
    extraTicketCost: 0,
    maxThreshold: null,  // no upper limit
    features: [
      "Tickets illimités, gestion multi-marques",
      "Intégrations personnalisées (ERP/WMS/etc.)",
      "Support VIP 24/7 avec SLA renforcé",
      "Reporting KPI sur mesure + audits stratégiques"
    ]
  }
];

type Interval = "month" | "year";

export function PricingManaged() {
  const [interval, setInterval] = useState<Interval>("month");
  const [ticketVolume, setTicketVolume] = useState<number>(2000); // default slider
  const [employeeCount, setEmployeeCount] = useState<number>(4);  // user-chosen # employees
  const [employeeCost, setEmployeeCost] = useState<number>(2500); // cost per employee

  // Determine the correct plan based on "jump" thresholds
  const plan = getPlanForVolume(ticketVolume);

  // In-house cost
  const inhouseMonthlyCost = employeeCount * employeeCost;

  // Our monthly cost based on coverage + extra tickets
  const ourMonthlyCost = calculatePlanPrice(plan, ticketVolume, interval);

  return (
    <section className="relative overflow-hidden py-12 px-4 sm:px-6 lg:px-8" 
      style={{ background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)' }}>

      {/* Glow grid */}
      <div className="absolute inset-0 -z-10">
        <div 
          className="absolute inset-0 opacity-30"
          style={{
            backgroundImage: `
              radial-gradient(circle at 50% 50%, rgba(0,255,157,0.1) 0%, transparent 50%),
              linear-gradient(rgba(0,255,157,0.05) 2px, transparent 2px),
              linear-gradient(90deg, rgba(0,255,157,0.05) 2px, transparent 2px)
            `,
            backgroundSize: 'cover, 30px 30px, 30px 30px',
            animation: 'grid-move 20s linear infinite'
          }}
        />
      </div>

      <div className="container relative mx-auto max-w-7xl">
        {/* Heading */}
        <div className="text-center mb-8">
          <WordFadeIn
            words="Calculez votre plan & votre ROI"
            className="text-3xl md:text-4xl font-bold text-white mb-2 drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]"
          />
          <p className="text-lg text-gray-300">
            Sélectionnez votre volume de tickets et comparez votre coût interne 
            face à notre offre externalisée.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Left: Sliders + ROI */}
          <div className="space-y-6">
            {/* Sliders container */}
            <div className="bg-[rgba(26,26,46,0.7)] p-4 rounded-xl border border-[rgba(0,255,157,0.2)] space-y-4">
              {/* Ticket Volume Slider */}
              <div className="space-y-2">
                <label className="text-gray-300 block">
                  Volume mensuel de tickets : 
                  <span className="text-[#00ff9d] font-bold ml-2">
                    {ticketVolume}
                  </span>
                </label>
                <input
                  type="range"
                  min={100}
                  max={5101} 
                  step={100}
                  value={ticketVolume}
                  onChange={(e) => setTicketVolume(parseInt(e.target.value, 10))}
                  className="w-full accent-[#00ff9d]"
                />
                <p className="text-xs text-gray-400">
                  Jusqu'à 20 000 avant de passer à "Entreprise" (illimité).
                </p>
              </div>

              {/* Employee Count Slider */}
              <div className="space-y-2">
                <label className="text-gray-300 block">
                  Nombre d'employés SAV : 
                  <span className="text-[#00ff9d] font-bold ml-2">
                    {employeeCount}
                  </span>
                </label>
                <input
                  type="range"
                  min={1}
                  max={20}
                  step={1}
                  value={employeeCount}
                  onChange={(e) => setEmployeeCount(parseInt(e.target.value, 10))}
                  className="w-full accent-[#00ff9d]"
                />
                <p className="text-xs text-gray-400">
                  Ajustez en fonction de votre configuration actuelle.
                </p>
              </div>

              {/* Employee Cost Input */}
              <div className="space-y-2">
                <label className="text-gray-300 block">
                  Coût mensuel par employé : 
                  <span className="text-[#00ff9d] font-bold ml-2">
                    {employeeCost}€
                  </span>
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    type="range"
                    min={500}
                    max={5000}
                    step={100}
                    value={employeeCost}
                    onChange={(e) => setEmployeeCost(parseInt(e.target.value, 10))}
                    className="w-full accent-[#00ff9d]"
                  />
                  <button
                    onClick={() => setEmployeeCost(2500)}
                    className="text-xs px-2 py-1 rounded-full bg-[rgba(0,255,157,0.1)] 
                             border border-[#00ff9d] text-[#00ff9d] hover:bg-[rgba(0,255,157,0.2)]"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>

            {/* ROI Comparison */}
            <ROISection
              ticketVolume={ticketVolume}
              inhouseMonthlyCost={employeeCount * employeeCost}
              yourMonthlyCost={ourMonthlyCost}
              planName={plan.name}
            />
          </div>

          {/* Right: Switch + Plan Card */}
          <div className="space-y-6">
            <div className="flex items-center justify-center space-x-4 
                          bg-[rgba(26,26,46,0.7)] backdrop-blur-md p-2 rounded-full 
                          border border-[rgba(0,255,157,0.2)] w-fit mx-auto">
              <span className={`text-sm ${interval === 'month' ? 'text-[#00ff9d]' : 'text-gray-400'}`}>
                Mensuel
              </span>
              <Switch
                id="interval"
                onCheckedChange={(checked) => setInterval(checked ? "year" : "month")}
                className="data-[state=checked]:bg-[#00ff9d]"
              />
              <span className={`text-sm ${interval === 'year' ? 'text-[#00ff9d]' : 'text-gray-400'}`}>
                Annuel
              </span>
              <span className="ml-2 inline-block whitespace-nowrap rounded-full 
                              bg-[rgba(0,255,157,0.1)] border border-[#00ff9d] 
                              px-2.5 py-1 text-[11px] font-semibold uppercase tracking-wide text-[#00ff9d]">
                -20% si annuel
              </span>
            </div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="h-full"
            >
              <div className="relative bg-[rgba(26,26,46,0.7)] backdrop-blur-md p-8 rounded-xl 
                              border border-[rgba(0,255,157,0.2)] 
                              hover:shadow-[0_0_30px_rgba(0,255,157,0.3)] hover:-translate-y-1 
                              transition-all duration-300"
              >
                <h2 className="text-2xl font-bold text-white mb-2">{plan.name}</h2>
                <p className="text-gray-400 mb-6">{plan.description}</p>

                {plan.monthlyPrice === null ? (
                  <div className="text-[#00ff9d] text-4xl font-bold mb-6">Sur devis</div>
                ) : (
                  <>
                    <div className="flex items-baseline mb-4">
                      <span className="text-5xl font-bold text-[#00ff9d] drop-shadow-[0_0_8px_rgba(0,255,157,0.3)]">
                        {ourMonthlyCost === 0 
                          ? "???" 
                          : ourMonthlyCost.toLocaleString()
                        }
                      </span>
                      <span className="ml-2 text-gray-400 text-base">
                        €/mois
                      </span>
                    </div>
                    <p className="text-sm text-gray-400 mb-4">
                      (Base + coûts de tickets supplémentaires)
                    </p>
                  </>
                )}

                {/* Features */}
                <ul className="space-y-4 mb-6">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="flex items-start space-x-3 text-gray-300">
                      <CheckIcon className="h-5 w-5 text-[#00ff9d] flex-shrink-0 mt-0.5" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>

                <a
                  href="https://calendly.com/contact-braincorp/30min"
                  className="w-full inline-block text-center bg-[#00ff9d] text-[rgb(37,0,52)] 
                             font-semibold uppercase py-3 rounded-full 
                             hover:shadow-[0_0_20px_rgba(0,255,157,0.4)] transition-all"
                >
                  {plan.monthlyPrice === null ? "Nous Contacter" : "Souscrire"}
                </a>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      <style>{`
        @keyframes grid-move {
          0% { background-position: 0 0, 0 0, 0 0; }
          100% { background-position: 0 0, 30px 30px, 30px 30px; }
        }
      `}</style>
    </section>
  );
}

/* We ONLY jump to next plan if volume > plan.maxThreshold. 
   e.g. Essentiel covers up to 2000 (base coverage=500 but extra until 2000).
   If volume=2001 => Croissance, if volume=5001 => Scale, if volume=20001 => Entreprise.
*/
function getPlanForVolume(volume: number): PlanData {
  for (let i = 0; i < PLANS.length; i++) {
    const plan = PLANS[i];
    if (plan.maxThreshold === null) {
      // this is the last plan => enterprise
      return plan;
    }
    if (volume <= plan.maxThreshold) {
      return plan;
    }
  }
  return PLANS[PLANS.length - 1];
}

/*
  Calculate total monthly cost = base price + extra tickets * extraTicketCost
*/
function calculatePlanPrice(plan: PlanData, volume: number, interval: Interval): number {
  // if it's "Entreprise" => just return 0 => "Sur devis"
  if (plan.monthlyPrice === null) return 0;

  // get base cost in cents
  const baseCost = interval === "year"
    ? (plan.annualPrice ?? plan.monthlyPrice)
    : plan.monthlyPrice;

  // how many tickets are above plan.baseCoverage, but within plan.maxThreshold 
  // if plan.maxThreshold is 2000 => up to 2000 belongs to this plan
  let coverage = plan.baseCoverage;
  if (volume < coverage) coverage = volume; // no extra if user volume < coverage
  const extraTickets = volume > coverage ? volume - coverage : 0;

  // but also cap volume at plan.maxThreshold
  if (plan.maxThreshold !== null && volume > plan.maxThreshold) {
    // e.g. user picks 2500 in "Essentiel"? not possible because we said we remain in Essentiel up to 2000
    // but let's handle it gracefully
    const planCoverageRange = plan.maxThreshold - coverage;
    const usedExtra = volume - coverage;
    const finalExtra = usedExtra > planCoverageRange ? planCoverageRange : usedExtra;

    // finalExtra is how many tickets get the extra cost
    const extraCost = finalExtra * plan.extraTicketCost;
    return (baseCost + extraCost) / 100;
  } else {
    // normal scenario
    const extraCost = extraTickets * plan.extraTicketCost;
    return (baseCost + extraCost) / 100;
  }
}

/*
  ROI Section: compares in-house monthly vs. your monthly 
*/
type ROISectionProps = {
  ticketVolume: number;
  inhouseMonthlyCost: number;
  yourMonthlyCost: number;
  planName: string;
};

const ROISection: React.FC<ROISectionProps> = ({
  ticketVolume,
  inhouseMonthlyCost,
  yourMonthlyCost,
  planName
}) => {
  const monthlySavings = inhouseMonthlyCost - yourMonthlyCost;
  const yearlySavings = monthlySavings * 12;
  const savingsPercentage = inhouseMonthlyCost > 0
    ? ((inhouseMonthlyCost - yourMonthlyCost) / inhouseMonthlyCost) * 100
    : 0;

  return (
    <a 
      href="https://calendly.com/contact-braincorp/30min"
      className="block bg-[rgba(26,26,46,0.7)] p-4 rounded-xl border border-[rgba(0,255,157,0.2)]
                hover:border-[#00ff9d] hover:shadow-[0_0_30px_rgba(0,255,157,0.3)] 
                transition-all duration-300"
    >
      <h3 className="text-xl font-bold mb-4 text-white">
        Votre ROI estimé
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        {/* In-house */}
        <div className="p-4 bg-[rgba(26,26,46,0.6)] rounded-xl border border-[rgba(0,255,157,0.2)]">
          <h4 className="text-[#00ffff] text-lg font-semibold mb-1">Support Interne</h4>
          <p className="text-sm text-gray-300 mb-2">
            ~{ticketVolume} tickets avec votre équipe
          </p>
          <p className="text-2xl font-bold text-[#00ffff]">
            {inhouseMonthlyCost.toLocaleString()} € /mois
          </p>
        </div>

        {/* Our Service */}
        <div className="p-4 bg-[rgba(26,26,46,0.6)] rounded-xl border border-[rgba(0,255,157,0.2)]">
          <h4 className="text-[#00ff9d] text-lg font-semibold mb-1">{planName}</h4>
          {yourMonthlyCost === 0 ? (
            <p className="text-2xl font-bold text-[#00ff9d]">Sur devis</p>
          ) : (
            <>
              <p className="text-sm text-gray-300 mb-2">
                Externalisé à 100%
              </p>
              <p className="text-2xl font-bold text-[#00ff9d]">
                {yourMonthlyCost.toLocaleString()} € /mois
              </p>
            </>
          )}
        </div>
      </div>

      {/* Savings */}
      {yourMonthlyCost > 0 && (
        <div className="text-center bg-[rgba(0,255,157,0.1)] rounded-xl border border-[#00ff9d] p-4">
          <h4 className="text-lg text-white font-semibold">Économies Potentielles</h4>
          <div className="flex justify-center space-x-8 mt-4">
            <div>
              <p className="text-sm text-gray-300">Par mois</p>
              <p className="text-2xl font-bold text-white">
                {monthlySavings.toLocaleString()} €
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-300">Par an</p>
              <p className="text-2xl font-bold text-white">
                {yearlySavings.toLocaleString()} €
              </p>
            </div>
          </div>
          <p className="mt-2 text-sm text-gray-300">
            Soit ~{savingsPercentage.toFixed(0)}% d'économies
          </p>
        </div>
      )}
    </a>
  );
};
