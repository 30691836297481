import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShieldCheckIcon } from '@heroicons/react/24/outline';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  const renderList = (items: string[]) => (
    <ul className="list-disc ml-8 space-y-2 text-gray-300">
      {items.map((item, index) => (
        <li key={index} className="hover:text-[#00ff9d] transition-colors duration-300">{item}</li>
      ))}
    </ul>
  );

  return (
    <div className="relative max-w-4xl mx-auto p-8 bg-[rgba(26,26,46,0.7)] backdrop-blur-md text-white rounded-xl border border-[rgba(0,255,157,0.2)]">
      {/* Background Effects */}
      <div className="absolute -top-24 -left-24 w-96 h-96 bg-[#00ff9d] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />
      <div className="absolute -bottom-24 -right-24 w-96 h-96 bg-[#00ffff] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />
      
      {/* Grid Background */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0" style={{
          backgroundImage: `
            linear-gradient(rgba(0,255,157,0.05) 1px, transparent 1px),
            linear-gradient(90deg, rgba(0,255,157,0.05) 1px, transparent 1px)
          `,
          backgroundSize: '20px 20px'
        }} />
      </div>

      {/* Logo Section */}
      <div className="relative flex flex-col items-center mb-12">
        <div className="relative w-32 h-32">
          {/* Glowing ring */}
          <div className="absolute inset-0 rounded-full bg-[rgba(0,255,157,0.1)] border border-[#00ff9d] shadow-[0_0_30px_rgba(0,255,157,0.3)]" />
          
          {/* Logo container */}
          <div className="absolute inset-0 flex items-center justify-center p-6">
            <img 
              src="/logo192.webp" 
              alt="Unanimityai logo" 
              className="w-full h-full object-contain"
            />
          </div>
        </div>
        <ShieldCheckIcon className="absolute -top-4 -right-4 w-12 h-12 text-[#00ff9d] drop-shadow-[0_0_8px_rgba(0,255,157,0.3)]" />
      </div>

      {/* Title Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4 text-white drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]">
          {t('privacyPolicy.title')}
        </h1>
        <p className="text-[#00ff9d] font-medium">
          <strong>{t('privacyPolicy.lastUpdated')}</strong>
        </p>
      </div>

      {/* Content */}
      <div className="space-y-8 relative">
        <p className="text-gray-300 leading-relaxed">{t('privacyPolicy.introduction')}</p>
        
        {/* Section styling */}
        {[1, 2, 3, 4, 5, 6, 7, 8].map((section) => (
          <div key={section} className="relative bg-[rgba(26,26,46,0.5)] p-6 rounded-lg border border-[rgba(0,255,157,0.1)] hover:border-[#00ff9d] transition-colors duration-300">
            <h2 className="text-2xl font-bold mb-4 text-white drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]">
              {t(`privacyPolicy.sections.${section}.title`)}
            </h2>
            <p className="text-gray-300 mb-4 leading-relaxed">
              {t(`privacyPolicy.sections.${section}.description`)}
            </p>
            
            {/* Render lists if they exist */}
            {t(`privacyPolicy.sections.${section}.items`, { returnObjects: true, defaultValue: false }) && 
              renderList(t(`privacyPolicy.sections.${section}.items`, { returnObjects: true }) as string[])}
            
            {/* Special handling for section 5 contact info */}
            {section === 5 && (
              <p className="text-gray-300 mt-4">
                {t('privacyPolicy.sections.5.contact')} 
                <a href="mailto:data@unanimity.ai" className="text-[#00ff9d] hover:text-[#00ffff] transition-colors duration-300 ml-1">
                  data@unanimity.ai
                </a>
              </p>
            )}
          </div>
        ))}

        {/* Contact Information */}
        <address className="not-italic bg-[rgba(26,26,46,0.5)] p-6 rounded-lg border border-[rgba(0,255,157,0.1)] text-gray-300">
          Unanimity AI<br />
          Cagnes sur mer, 06800 France<br />
          <a href="mailto:contact@unanimity.ai" className="text-[#00ff9d] hover:text-[#00ffff] transition-colors duration-300">
            contact@unanimity.ai
          </a>
        </address>

        {/* Agreement and Thank You */}
        <p className="text-gray-300 italic">{t('privacyPolicy.agreement')}</p>
        <p className="text-[#00ff9d] font-bold text-center">{t('privacyPolicy.thankYou')}</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;