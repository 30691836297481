import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faLock, faFileContract, faGavel } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const SocialLink: React.FC<{ href: string; icon: any; text: string }> = ({ href, icon, text }) => (
  <a 
    href={href} 
    target="_blank" 
    rel="noopener noreferrer" 
    className="transition-all duration-300 hover:scale-110 flex items-center space-x-2"
  >
    <FontAwesomeIcon 
      icon={icon} 
      size="lg" 
      className="text-gray-400 hover:text-[#00ff9d] hover:drop-shadow-[0_0_8px_rgba(0,255,157,0.5)]" 
    />
    <span className="text-gray-400 hover:text-[#00ff9d] text-sm">
      {text}
    </span>
  </a>
);

const LegalLink: React.FC<{ href: string; icon: any; text: string }> = ({ href, icon, text }) => (
  <a 
    href={href} 
    target="_blank" 
    rel="noopener noreferrer" 
    className="flex items-center space-x-2 text-gray-400 hover:text-[#00ff9d] transition-all duration-300 group"
  >
    <FontAwesomeIcon 
      icon={icon} 
      size="sm" 
      className="group-hover:drop-shadow-[0_0_8px_rgba(0,255,157,0.5)]" 
    />
    <span className="group-hover:drop-shadow-[0_0_8px_rgba(0,255,157,0.5)]">
      {text}
    </span>
  </a>
);

const SectionFooter: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="relative bg-[#1a1a2e]">
      {/* Top border with gradient */}
      <div className="h-px bg-gradient-to-r from-transparent via-[#00ff9d] to-transparent opacity-30" />

      {/* Main footer content */}
      <div className="container mx-auto py-12 px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Column 1 - Social Links */}
          <div className="flex flex-col items-center md:items-start space-y-4">
            <h3 className="text-white text-lg font-semibold mb-4 drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]">
              {t('footer.social.title')}
            </h3>
            <div className="flex flex-col space-y-3">
              <SocialLink 
                href="https://twitter.com/unanimity_ai" 
                icon={faXTwitter} 
                text={t('footer.socialLinks.twitter')} 
              />
              <SocialLink 
                href="https://instagram.com/unanimity_ai" 
                icon={faInstagram} 
                text={t('footer.socialLinks.instagram')} 
              />
              <SocialLink 
                href="https://linkedin.com/showcase/unanimity-ai" 
                icon={faLinkedin} 
                text={t('footer.socialLinks.linkedin')} 
              />
            </div>
          </div>

          {/* Column 2 - Logo */}
          <div className="flex justify-center items-center">
            <div className="relative group">
              <div className="absolute inset-0 bg-[#00ff9d] opacity-0 group-hover:opacity-20 blur-xl transition-opacity duration-500" />
              <img 
                src="/circle6.webp" 
                alt="Logo" 
                className="w-24 h-24 object-contain transition-all duration-300 group-hover:drop-shadow-[0_0_15px_rgba(0,255,157,0.5)]" 
              />
            </div>
          </div>

          {/* Column 3 - Legal Links */}
          <div className="flex flex-col items-center md:items-end space-y-4">
            <h3 className="text-white text-lg font-semibold mb-4 drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]">
              {t('footer.legal.title')}
            </h3>
            <div className="flex flex-col space-y-3">
              <LegalLink 
                href="/privacy-policy" 
                icon={faLock} 
                text={t('footer.legalLinks.privacyPolicy')} 
              />
              <LegalLink 
                href="/terms-of-use" 
                icon={faFileContract} 
                text={t('footer.legalLinks.termsOfUse')} 
              />
              <LegalLink 
                href="/legal-notice" 
                icon={faGavel} 
                text={t('footer.legalLinks.legalNotice')} 
              />
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-12 pt-8 border-t border-gray-800">
          <p className="text-center text-white text-sm">
            &copy; {new Date().getFullYear()} Unanimity AI. {t('footer.copyright')}
          </p>
        </div>
      </div>

      {/* Background grid */}
      <div className="absolute inset-0 -z-10 opacity-5">
        <div className="w-full h-full" style={{
          backgroundImage: 'linear-gradient(#00ff9d 1px, transparent 1px), linear-gradient(90deg, #00ff9d 1px, transparent 1px)',
          backgroundSize: '20px 20px'
        }} />
      </div>

      {/* Bottom border with gradient */}
      <div className="h-px bg-gradient-to-r from-transparent via-[#00ff9d] to-transparent opacity-30" />
    </footer>
  );
};

export default SectionFooter;