import React from "react";
import { useTranslation } from 'react-i18next';
import WordFadeIn from "./WordFadeIn";
import { UserIcon, ShoppingBagIcon, TruckIcon } from '@heroicons/react/24/outline';

const FeatureItem: React.FC<{ icon: React.ReactNode, title: string, description: string }> = ({ icon, title, description }) => (
  <div className="flex items-start mb-8 p-6 bg-[rgba(26,26,46,0.7)] backdrop-blur-md rounded-lg border border-[rgba(0,255,157,0.2)] transition-all duration-300 hover:border-[#00ff9d] hover:shadow-[0_0_20px_rgba(0,255,157,0.3)] hover:-translate-y-1">
    <div className="flex-shrink-0 mr-4">
      <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-[rgba(0,255,157,0.1)] text-[#00ff9d] border border-[#00ff9d] shadow-[0_0_15px_rgba(0,255,157,0.3)]">
        {React.cloneElement(icon as React.ReactElement, { className: "h-6 w-6" })}
      </div>
    </div>
    <div>
      <h3 className="text-lg font-medium text-white mb-2 drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]">{title}</h3>
      <p className="text-gray-300">{description}</p>
    </div>
  </div>
);

const SectionAgent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="relative overflow-hidden py-20" style={{ background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)' }}>
      {/* Animated background grid */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0" style={{
          backgroundImage: `
            linear-gradient(to right, rgba(0,255,157,0.1) 1px, transparent 1px),
            linear-gradient(to bottom, rgba(0,255,157,0.1) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px',
          maskImage: 'radial-gradient(circle at 50% 50%, black, transparent)',
        }} />
      </div>

      {/* Glow effects */}
      <div className="absolute -top-24 -left-24 w-96 h-96 bg-[#00ff9d] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />
      <div className="absolute -bottom-24 -right-24 w-96 h-96 bg-[#00ffff] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />

      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <WordFadeIn 
            words={t('sectionAgent.title')}
            className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-6 drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]"
          />
          <p className="text-xl md:text-2xl text-gray-300 max-w-3xl mx-auto drop-shadow-[0_0_8px_rgba(255,255,255,0.1)]">
            {t('sectionAgent.subtitle')}
          </p>
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
          <div className="w-full lg:w-1/2">
            <div className="relative">
              {/* Image container with cyberpunk frame */}
              <div className="relative bg-[rgba(26,26,46,0.7)] backdrop-blur-md p-4 rounded-lg border border-[rgba(0,255,157,0.2)] transition-all duration-300 hover:border-[#00ff9d] hover:shadow-[0_0_20px_rgba(0,255,157,0.3)]">
                {/* Corner decorations */}
                <div className="absolute top-0 left-0 w-8 h-8 border-t-2 border-l-2 border-[#00ff9d] rounded-tl-lg" />
                <div className="absolute top-0 right-0 w-8 h-8 border-t-2 border-r-2 border-[#00ff9d] rounded-tr-lg" />
                <div className="absolute bottom-0 left-0 w-8 h-8 border-b-2 border-l-2 border-[#00ff9d] rounded-bl-lg" />
                <div className="absolute bottom-0 right-0 w-8 h-8 border-b-2 border-r-2 border-[#00ff9d] rounded-br-lg" />
                
                {/* Scan line effect */}
                <div className="absolute inset-0 overflow-hidden rounded-lg pointer-events-none">
                  <div 
                    className="w-full h-[2px] bg-[#00ff9d] opacity-30"
                    style={{
                      animation: 'scan 2s linear infinite',
                      boxShadow: '0 0 10px #00ff9d'
                    }}
                  />
                </div>

                <img 
                  src="/agentia.webp" 
                  alt="AI Agent Visualization" 
                  className="w-full h-auto rounded-lg relative z-10"
                />
              </div>

              {/* Glow effect behind image */}
              <div
                className="absolute inset-0 -z-10 blur-[40px]"
                style={{
                  background: 'radial-gradient(circle at 50% 50%, rgba(0,255,157,0.2) 0%, transparent 70%)'
                }}
              />
            </div>
          </div>

          <div className="w-full lg:w-1/2 space-y-8">
            <FeatureItem
              icon={<UserIcon />}
              title={t('sectionAgent.features.0.title')}
              description={t('sectionAgent.features.0.description')}
            />
            <FeatureItem
              icon={<ShoppingBagIcon />}
              title={t('sectionAgent.features.1.title')}
              description={t('sectionAgent.features.1.description')}
            />
            <FeatureItem
              icon={<TruckIcon />}
              title={t('sectionAgent.features.2.title')}
              description={t('sectionAgent.features.2.description')}
            />
          </div>
        </div>
      </div>

      {/* Add the scan line animation */}
      <style>{`
        @keyframes scan {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(100%);
          }
        }
      `}</style>
    </section>
  );
};

export default SectionAgent;