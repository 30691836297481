import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import NavMenu from './NavMenu';
import LanguageSwitcher from './LanguageSwitcher';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isManaged = location.pathname === '/managed';
  const isRoot = location.pathname === '/';
  const isPricingManaged = location.pathname === '/pricing-managed';

  // Don't render anything if we're at the root path
  if (isRoot) return null;

  const links = [
    { 
      href: isManaged ? '/managed-pricing' : '/pricing', 
      label: t('header.pricing') 
    },
    { href: '/get-in-touch', label: t('header.getInTouch') },
  ];

  return (
    <div className="fixed top-0 z-50 w-full flex justify-center pt-4" 
      style={{
        background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)'
      }}>
      <header
        className="
          max-w-5xl
          w-full
          mx-4
          flex
          items-center
          justify-between
          bg-[rgba(26,26,46,0.9)]
          text-white
          py-3
          px-6
          rounded-full
          shadow-[0_0_15px_rgba(0,255,157,0.2)]
          border
          border-[rgba(0,255,157,0.2)]
          backdrop-blur-lg
        "
      >
        {/* Brand / Logo */}
        <div className="flex items-center">
          <a 
            href="/" 
            className="flex items-center space-x-2 hover:text-[#00ff9d] transition-colors duration-300"
          >
            <img src="/lobow.png" alt="Logo" className="h-8 w-8" />
            <span className="font-bold text-lg tracking-wide">
              {t('header.brandName')}
            </span>
          </a>
        </div>

        {/* Navigation */}
        <NavMenu links={links} hideNav={isPricingManaged} />
      </header>
    </div>
  );
};

export default Header;
