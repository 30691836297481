import React from "react";
import { useTranslation } from 'react-i18next';
import WordFadeIn from "./WordFadeIn";
import { Link } from "react-router-dom";

interface Metric {
  value: string;
  label: string;
}

const MetricItem: React.FC<Metric> = ({ value, label }) => (
  <div className="relative group">
    {/* Glow effect on hover */}
    <div className="absolute inset-0 bg-gradient-to-r from-[#00ff9d] to-[#00ffff] opacity-0 group-hover:opacity-20 blur-xl transition-opacity duration-500" />
    
    <div className="relative bg-[rgba(26,26,46,0.7)] backdrop-blur-md p-8 rounded-lg border border-[rgba(0,255,157,0.2)] transition-all duration-300 hover:border-[#00ff9d] hover:shadow-[0_0_20px_rgba(0,255,157,0.3)] hover:-translate-y-1">
      <span className="block text-5xl font-bold text-[#00ff9d] mb-4 drop-shadow-[0_0_8px_rgba(0,255,157,0.3)]">
        {value}
      </span>
      <p className="text-gray-300 text-lg">
        {label}
      </p>
    </div>
  </div>
);

const SectionMetrics: React.FC = () => {
  const { t } = useTranslation();
  const metrics: Metric[] = t('sectionMetrics.metrics', { returnObjects: true });

  return (
    <section className="relative overflow-hidden py-20" style={{ background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)' }}>
      {/* Background Effects */}
      <div className="absolute -top-24 -left-24 w-96 h-96 bg-[#00ff9d] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />
      <div className="absolute -bottom-24 -right-24 w-96 h-96 bg-[#00ffff] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />

      {/* Subtle grid background */}
      <div className="absolute inset-0 -z-10 opacity-5">
        <div className="w-full h-full" style={{
          backgroundImage: 'linear-gradient(#00ff9d 1px, transparent 1px), linear-gradient(90deg, #00ff9d 1px, transparent 1px)',
          backgroundSize: '20px 20px'
        }} />
      </div>

      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <WordFadeIn 
            words={t('sectionMetrics.title')}
            className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-6 drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]"
          />
          <p className="text-xl md:text-2xl text-gray-300 max-w-3xl mx-auto drop-shadow-[0_0_8px_rgba(255,255,255,0.1)]">
            {t('sectionMetrics.description')}
          </p>
        </div>

        <div className="relative mb-16">
          {/* Metrics grid with animated background */}
          <div className="relative z-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {metrics.map((metric, index) => (
              <MetricItem key={index} {...metric} />
            ))}
          </div>
        </div>

        <div className="text-center">
          <Link 
            to="/pricing"
            className="inline-flex items-center px-8 py-3 text-base font-semibold uppercase tracking-wider text-[#00ff9d] border border-[#00ff9d] rounded-full transition-all duration-300 hover:bg-[rgba(0,255,157,0.1)] hover:shadow-[0_0_20px_rgba(0,255,157,0.3)] hover:scale-105"
          >
            {t('sectionMetrics.cta')}
          </Link>
        </div>
      </div>

      {/* Decorative corner elements */}
      <div className="absolute top-0 left-0 w-32 h-32">
        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-[#00ff9d] to-transparent" />
        <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-[#00ff9d] to-transparent" />
      </div>
      <div className="absolute top-0 right-0 w-32 h-32">
        <div className="absolute top-0 right-0 w-full h-1 bg-gradient-to-l from-[#00ff9d] to-transparent" />
        <div className="absolute top-0 right-0 w-1 h-full bg-gradient-to-b from-[#00ff9d] to-transparent" />
      </div>
      <div className="absolute bottom-0 left-0 w-32 h-32">
        <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-[#00ff9d] to-transparent" />
        <div className="absolute bottom-0 left-0 w-1 h-full bg-gradient-to-t from-[#00ff9d] to-transparent" />
      </div>
      <div className="absolute bottom-0 right-0 w-32 h-32">
        <div className="absolute bottom-0 right-0 w-full h-1 bg-gradient-to-l from-[#00ff9d] to-transparent" />
        <div className="absolute bottom-0 right-0 w-1 h-full bg-gradient-to-t from-[#00ff9d] to-transparent" />
      </div>
    </section>
  );
};

export default SectionMetrics;