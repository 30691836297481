import React, { useEffect, useState } from 'react';
import { useAmplitude } from './useAmplitude';
import SectionFooter from './SectionFooter';
import HeroSectionService from './HeroSectionService';
import { ArrowPathIcon, ChatBubbleLeftRightIcon, SparklesIcon, ShieldCheckIcon, ChartBarIcon, CurrencyEuroIcon, ClockIcon, UserGroupIcon } from '@heroicons/react/24/outline';

const Managed: React.FC = () => {
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent('Page View', { page: 'Managed Service' });
  }, [logEvent]);

  return (
    <div className="bg-[#1a1a2e] text-white">
      <HeroSectionService />
      <SectionTimeline />
      <SectionExperience />
      <SectionHorizontalFeatures />
      <SectionTestimonialsSlider />
      <SectionCTA />
      <SectionFooter />
    </div>
  );
};

export default Managed;

/* 
  2) TIMELINE: "HOW IT WORKS" 
  A vertical or horizontal timeline with lines, not bullet points.
*/
const SectionTimeline: React.FC = () => {
  return (
    <section className="relative py-20 px-6 bg-[#1a1a2e]">
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl font-extrabold text-white mb-16 text-center">
          Comment ça marche ?
        </h2>
        <div className="flex flex-col lg:flex-row gap-8 items-center">
          {/* Timeline Steps (60% width) */}
          <div className="w-full lg:w-[60%]">
            <div className="relative border-l-2 border-gradient-y-green"
                 style={{
                   borderImage: 'linear-gradient(to bottom, rgba(0,255,157,0.05), rgba(0,255,157,0.4), rgba(0,255,157,0.05)) 1'
                 }}>
              <TimelineStep
                stepTitle="Onboarding simplifié"
                description="Nous récupérons vos infos clés (politiques de retour, branding, accès) pour former rapidement l'IA et configurer le portail."
                stepNumber="01"
              />
              <TimelineStep
                stepTitle="Support automatisé + humain"
                description="Notre IA gère 80% des demandes (retours, remboursements, suivi colis). Les cas complexes sont traités par notre équipe humaine."
                stepNumber="02"
              />
              <TimelineStep
                stepTitle="Suivi en temps réel"
                description="Accédez à un portail simple pour voir l'avancée des tickets, valider des décisions cruciales et analyser les performances."
                stepNumber="03"
              />
            </div>
          </div>

          {/* Images Section (40% width) */}
          <div className="relative w-full lg:w-[40%] h-[600px] hidden lg:block">
            {/* First Image */}
            <div className="absolute top-0 right-0 w-[80%] h-[300px] rounded-xl overflow-hidden 
                          border-4 border-[#1a1a2e] shadow-[0_0_30px_rgba(0,255,157,0.2)]
                          transform hover:scale-105 transition-transform duration-500">
              <img
                src="/stats1.webp"
                alt="Dashboard Vue"
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-[#1a1a2e] to-transparent opacity-50" />
            </div>

            {/* Second Image */}
            <div className="absolute top-[150px] right-[60px] w-[80%] h-[300px] rounded-xl overflow-hidden 
                          border-4 border-[#1a1a2e] shadow-[0_0_30px_rgba(0,255,157,0.2)]
                          transform hover:scale-105 transition-transform duration-500">
              <img
                src="/agentia2.webp"
                alt="Analytics Vue"
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-[#1a1a2e] to-transparent opacity-50" />
            </div>

            {/* Third Image */}
            <div className="absolute top-[300px] right-[30px] w-[80%] h-[300px] rounded-xl overflow-hidden 
                          border-4 border-[#1a1a2e] shadow-[0_0_30px_rgba(0,255,157,0.2)]
                          transform hover:scale-105 transition-transform duration-500">
              <img
                src="/socialmedia.webp"
                alt="Tickets Vue"
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-[#1a1a2e] to-transparent opacity-50" />
            </div>

            {/* Decorative Glow */}
            <div className="absolute -inset-4 bg-gradient-to-r from-[#00ff9d] to-[#00ffff] opacity-20 blur-3xl -z-10" />
          </div>
        </div>
      </div>
    </section>
  );
};

type TimelineStepProps = {
  stepTitle: string;
  description: string;
  stepNumber: string;
};

const TimelineStep: React.FC<TimelineStepProps> = ({ stepTitle, description, stepNumber }) => {
  return (
    <div className="mb-16 ml-12 relative group">
      <div className="absolute -left-[52px] top-0">
        <div className="relative">
          <div className="w-12 h-12 rounded-full bg-[rgba(0,255,157,0.1)] border border-[#00ff9d] 
                        flex items-center justify-center transition-all duration-300
                        group-hover:shadow-[0_0_20px_rgba(0,255,157,0.3)]">
            <span className="text-[#00ff9d] font-bold text-sm">{stepNumber}</span>
          </div>
          <div className="absolute inset-0 rounded-full blur-md bg-[#00ff9d] opacity-20 -z-10" />
        </div>
      </div>

      <div className="p-6 rounded-xl bg-[rgba(26,26,46,0.5)] border border-[rgba(0,255,157,0.1)] 
                    transition-all duration-300 group-hover:border-[rgba(0,255,157,0.3)]
                    group-hover:shadow-[0_0_30px_rgba(0,255,157,0.1)]">
        <h3 className="text-xl font-semibold text-[#00ff9d] mb-3 
                     group-hover:text-[#00ffdd] transition-colors duration-300">
          {stepTitle}
        </h3>
        <p className="text-gray-300 leading-relaxed">{description}</p>
      </div>

      <div className="absolute -left-[46px] top-12 h-[calc(100%+32px)] w-px bg-gradient-to-b 
                    from-[rgba(0,255,157,0.3)] to-transparent" />
    </div>
  );
};

/*
  3) HORIZONTAL FEATURES
  Show what your managed service handles, but with a horizontal scroll or 
  some unique layout. We'll do a horizontal scroller for a "cyber" vibe.
*/
const SectionHorizontalFeatures: React.FC = () => {
  return (
    <section className="relative py-24 px-6 bg-gradient-to-r from-[#1a1a2e] to-[#16213e]">
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl font-extrabold text-white text-center mb-8">
          Ce que nous gérons pour vous
        </h2>
        <p className="max-w-2xl mx-auto text-gray-300 text-center mb-16">
          Notre service complet couvre tous les aspects de votre SAV, 
          du premier contact client à la résolution finale, sans effort de votre part.
        </p>

        <div className="overflow-x-auto no-scrollbar pb-8">
          <div className="flex gap-8 min-w-[800px] px-4">
            <FeatureCard
              icon={ArrowPathIcon}
              title="Retours & Remboursements"
              description="Étiquettes de retour générées instantanément, suivis de colis, 
                remboursement automatique—sans intervention humaine."
              stats="98% automatisé"
              color="#00ff9d"
            />
            <FeatureCard
              icon={ChatBubbleLeftRightIcon}
              title="Multi-Canal"
              description="Nos agents IA répondent à vos clients par email, chat, 
                et réseaux sociaux—tous centralisés."
              stats="5+ canaux"
              color="#00ffff"
            />
            <FeatureCard
              icon={SparklesIcon}
              title="Piloté par IA + Expert Humain"
              description="80% des tickets gérés par l'IA, les cas sensibles escaladés 
                à nos spécialistes internes."
              stats="< 15min de réponse"
              color="#00ff9d"
            />
            <FeatureCard
              icon={ShieldCheckIcon}
              title="Surveillance 24/7"
              description="Un support disponible jour et nuit, avec alertes en temps 
                réel en cas de problème urgent."
              stats="99.9% disponibilité"
              color="#00ffff"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

type FeatureCardProps = {
  icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  stats: string;
  color: string;
};

const FeatureCard: React.FC<FeatureCardProps> = ({ icon: Icon, title, description, stats, color }) => {
  return (
    <div
      className="flex-shrink-0 w-72 bg-[rgba(26,26,46,0.7)] backdrop-blur-xl rounded-2xl p-8 
                 border border-[rgba(0,255,157,0.2)] group hover:border-[rgba(0,255,157,0.4)]
                 hover:shadow-[0_0_30px_rgba(0,255,157,0.2)] transition-all duration-500 relative"
    >
      {/* Background Glow */}
      <div
        className="absolute -top-10 -left-10 w-40 h-40 rounded-full blur-[80px] opacity-20 
                   transition-opacity duration-500 group-hover:opacity-40"
        style={{ backgroundColor: color }}
      />

      {/* Icon Container */}
      <div className="relative mb-6">
        <div className="w-14 h-14 rounded-xl bg-[rgba(0,255,157,0.1)] p-3
                      border border-[rgba(0,255,157,0.2)] group-hover:border-[rgba(0,255,157,0.4)]
                      transition-all duration-500">
          <Icon className="w-full h-full" style={{ color }} />
        </div>
      </div>

      {/* Content */}
      <h3 className="text-xl font-semibold mb-3 transition-colors duration-300"
          style={{ color }}>
        {title}
      </h3>
      
      <p className="text-gray-300 text-sm leading-relaxed mb-4">
        {description}
      </p>

      {/* Stats */}
      <div className="pt-4 border-t border-[rgba(255,255,255,0.1)]">
        <span className="text-lg font-semibold" style={{ color }}>
          {stats}
        </span>
      </div>

      {/* Hover line effect */}
      <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-[color] to-transparent 
                    opacity-0 group-hover:opacity-30 transition-opacity duration-500"
           style={{ '--tw-gradient-via-color': color } as React.CSSProperties} />
    </div>
  );
};

/*
  4) TESTIMONIALS - We'll do a "slider" or horizontal deck to avoid bullet points.
*/
const SectionTestimonialsSlider: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const testimonials = [
    {
      quote: "Nous avons divisé par deux notre temps passé sur les retours. Tout est géré, et nous n'avons plus à nous en soucier.",
      name: "Sophie M.",
      position: "Responsable E-Commerce, BelleBoutique",
      rating: 5,
      image: "/p2.jpg",
      color: "#00ff9d"
    },
    {
      quote: "Les réponses sont ultra-rapides, même la nuit. Nos clients sont bluffés, et nous aussi.",
      name: "David H.",
      position: "Président Directeur General, EnvoiDuNet",
      rating: 5,
      image: "/p3.jpg",
      color: "#00ffff"
    },
    {
      quote: "C'est comme avoir un service client 24/7, sans recruter de nouvelles personnes !",
      name: "Chloé A",
      position: "Fondatrice, Coeur De Truffe",
      rating: 5,
      image: "/p1.jpg",
      color: "#00ff9d"
    }
  ];

  // Auto-scroll effect
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((current) => (current + 1) % testimonials.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [testimonials.length]);

  return (
    <section className="relative py-16 px-6 bg-[#1a1a2e] overflow-hidden">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl md:text-4xl font-extrabold mb-16">
          Ce qu'en disent nos clients
        </h2>
        
        {/* Testimonials Carousel */}
        <div className="relative max-w-[800px] mx-auto">
          <div className="overflow-hidden">
            <div 
              className="grid grid-flow-col auto-cols-[100%] transition-transform duration-500 ease-in-out"
              style={{ transform: `translateX(-${activeIndex * 100}%)` }}
            >
              {testimonials.map((testimonial, index) => (
                <div 
                  key={index}
                  className="px-4 h-full"
                >
                  <TestimonialCard {...testimonial} />
                </div>
              ))}
            </div>
          </div>

          {/* Navigation Dots */}
          <div className="flex justify-center gap-2 mt-8">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => setActiveIndex(index)}
                className={`w-2 h-2 rounded-full transition-all duration-300 
                          ${activeIndex === index 
                            ? 'w-8 bg-[#00ff9d]' 
                            : 'bg-gray-500 hover:bg-[#00ff9d]'}`}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>

          {/* Arrow Navigation */}
          <button
            onClick={() => setActiveIndex((current) => (current - 1 + testimonials.length) % testimonials.length)}
            className="absolute -left-12 top-1/2 -translate-y-1/2 p-2 rounded-full 
                     bg-[rgba(0,255,157,0.1)] border border-[#00ff9d] 
                     hover:bg-[#00ff9d] hover:text-[#1a1a2e] transition-all duration-300
                     hidden md:block"
            aria-label="Previous testimonial"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button
            onClick={() => setActiveIndex((current) => (current + 1) % testimonials.length)}
            className="absolute -right-12 top-1/2 -translate-y-1/2 p-2 rounded-full 
                     bg-[rgba(0,255,157,0.1)] border border-[#00ff9d] 
                     hover:bg-[#00ff9d] hover:text-[#1a1a2e] transition-all duration-300
                     hidden md:block"
            aria-label="Next testimonial"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
};

type TestimonialCardProps = {
  quote: string;
  name: string;
  position: string;
  rating: number;
  image: string;
  color: string;
};

const TestimonialCard: React.FC<TestimonialCardProps> = ({ 
  quote, 
  name, 
  position, 
  rating, 
  image, 
  color 
}) => {
  return (
    <div className="relative pt-8 h-full">
      <div className="relative bg-[rgba(26,26,46,0.7)] backdrop-blur-xl rounded-xl p-8 
                     border border-[rgba(0,255,157,0.2)] group hover:border-[rgba(0,255,157,0.4)]
                     hover:shadow-[0_0_30px_rgba(0,255,157,0.2)] transition-all duration-500
                     h-full flex flex-col">
        {/* Quote Icon */}
        <div className="absolute -top-4 left-8 w-8 h-8 flex items-center justify-center 
                      rounded-full bg-[rgba(0,255,157,0.1)] border border-[rgba(0,255,157,0.3)]
                      shadow-lg">
          <svg
            className="w-4 h-4"
            fill={color}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.346 2.04-.217 3.1c.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003zm9.124 0c0-.88-.23-1.618-.69-2.217-.326-.42-.77-.692-1.327-.817-.56-.124-1.074-.13-1.54-.022-.16-.94.09-1.95.75-3.02.66-1.06 1.514-1.86 2.557-2.4L18.49 5c-.8.396-1.555.898-2.26 1.505-.708.607-1.34 1.305-1.894 2.094-.556.79-.97 1.68-1.24 2.69-.273 1-.345 2.04-.217 3.1.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003z" />
          </svg>
        </div>

        {/* Star Rating */}
        <div className="flex gap-1 mb-6">
          {[...Array(rating)].map((_, i) => (
            <svg
              key={i}
              className="w-5 h-5"
              fill={color}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          ))}
        </div>

        {/* Quote Text */}
        <p className="text-gray-200 text-base mb-6 leading-relaxed flex-grow">
          "{quote}"
        </p>

        {/* Profile Section - Always at bottom */}
        <div className="flex items-center gap-4 mt-auto pt-4 border-t border-[rgba(255,255,255,0.1)]">
          <div className="w-12 h-12 rounded-full overflow-hidden border-2"
               style={{ borderColor: color }}>
            <img
              src={image}
              alt={name}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="text-left">
            <h4 className="font-semibold" style={{ color }}>
              {name}
            </h4>
            <span className="text-gray-400 text-sm">{position}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

/*
  5) FINAL CTA 
*/
const SectionCTA: React.FC = () => {
  return (
    <section id="cta" className="relative py-20 px-6 bg-gradient-to-br from-[#1a1a2e] to-[#16213e]">
      <div className="container mx-auto flex flex-col items-center text-center">
        <h2 className="text-3xl md:text-4xl font-extrabold mb-4">
          Passez à l'action
        </h2>
        <p className="max-w-xl text-gray-300 mb-8">
          Confiez-nous la totalité de votre SAV pour libérer du temps et augmenter 
          la satisfaction client. Découvrez comment notre équipe et notre IA 
          peuvent transformer votre support dès aujourd'hui.
        </p>
        <div className="flex flex-col md:flex-row gap-4 items-center">
          <a
            href="https://calendly.com/contact-braincorp/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-8 py-3 text-base font-semibold uppercase tracking-wider text-white 
                    bg-[rgba(0,255,157,0.1)] border border-[#00ff9d] rounded-full transition-all 
                    duration-300 hover:bg-[#00ff9d] hover:text-[#1a1a2e] hover:shadow-[0_0_20px_rgba(0,255,157,0.4)] 
                    hover:scale-105"
          >
            Planifier un appel
          </a>
          <a
            href="/diy"
            className="inline-block px-8 py-3 text-base font-semibold uppercase tracking-wider text-white 
                    bg-transparent border border-white/20 rounded-full transition-all duration-300 
                    hover:bg-white/10 hover:border-white/40 hover:scale-105"
          >
            Je souhaite me débrouiller
          </a>
        </div>
      </div>
    </section>
  );
};

const SectionExperience: React.FC = () => {
  return (
    <section className="relative py-24 bg-[#1a1a2e] overflow-hidden">
      {/* Background Gradients */}
      <div className="absolute inset-0 opacity-30 pointer-events-none">
        <div className="absolute top-0 -left-4 w-72 h-72 bg-[#00ff9d] rounded-full mix-blend-multiply filter blur-[64px]" />
        <div className="absolute bottom-0 -right-4 w-72 h-72 bg-[#00ffff] rounded-full mix-blend-multiply filter blur-[64px]" />
      </div>

      <div className="container mx-auto px-6">
        <div className="flex flex-col lg:flex-row items-center gap-16">
          {/* Left side - Main text */}
          <div className="lg:w-1/2 relative">
            <h2 className="text-3xl md:text-4xl font-extrabold mb-6">
              <span className="text-white">Une expertise forgée par</span>{' '}
              <span className="text-[#00ff9d]">10 ans d'expérience</span>
            </h2>
            <p className="text-gray-300 text-lg leading-relaxed mb-8">
              Notre équipe combine une décennie d'expertise en e-commerce et logistique avec 
              les dernières avancées en intelligence artificielle. Cette alliance unique nous 
              permet d'offrir un service client exceptionnel, adapté aux défis modernes du commerce en ligne.
            </p>
            <div className="relative z-10">
              <a
                href="https://calendly.com/contact-braincorp/30min"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block px-8 py-3 text-base font-semibold uppercase tracking-wider 
                         text-white bg-[rgba(0,255,157,0.1)] border border-[#00ff9d] rounded-full 
                         transition-all duration-300 hover:bg-[#00ff9d] hover:text-[#1a1a2e] 
                         hover:shadow-[0_0_20px_rgba(0,255,157,0.4)] hover:scale-105"
              >
                Parlons de votre projet
              </a>
            </div>
          </div>

          {/* Right side - Stats */}
          <div className="lg:w-1/2 grid grid-cols-2 gap-6">
            <StatCard
              number="10M+"
              label="Colis expédiés"
              description="Traités et suivis avec succès"
              color="#00ff9d"
            />
            <StatCard
              number="98%"
              label="Taux de satisfaction"
              description="Clients satisfaits de notre service"
              color="#00ffff"
            />
            <StatCard
              number="24/7"
              label="Support client"
              description="Disponibilité garantie"
              color="#00ffff"
            />
            <StatCard
              number="10+"
              label="Années d'expérience"
              description="En e-commerce et logistique"
              color="#00ff9d"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

type StatCardProps = {
  number: string;
  label: string;
  description: string;
  color: string;
};

const StatCard: React.FC<StatCardProps> = ({ number, label, description, color }) => {
  return (
    <div className="relative group">
      <div className="p-6 rounded-xl bg-[rgba(26,26,46,0.7)] backdrop-blur-xl 
                    border border-[rgba(0,255,157,0.2)] group-hover:border-[rgba(0,255,157,0.4)]
                    transition-all duration-500 group-hover:shadow-[0_0_30px_rgba(0,255,157,0.2)]">
        {/* Background Glow */}
        <div
          className="absolute -inset-4 rounded-full blur-[80px] opacity-20 
                     transition-opacity duration-500 group-hover:opacity-40 -z-10"
          style={{ backgroundColor: color }}
        />

        {/* Content */}
        <h3 className="text-4xl font-bold mb-2" style={{ color }}>
          {number}
        </h3>
        <h4 className="text-white text-lg font-semibold mb-2">
          {label}
        </h4>
        <p className="text-gray-400 text-sm">
          {description}
        </p>

        {/* Bottom Gradient Line */}
        <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r 
                      from-transparent via-[color] to-transparent opacity-0 
                      group-hover:opacity-30 transition-opacity duration-500"
             style={{ '--tw-gradient-via-color': color } as React.CSSProperties} />
      </div>
    </div>
  );
};