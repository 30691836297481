import React, { useEffect } from 'react';
import { useAmplitude } from './useAmplitude';
import HeroSection from './HeroSection';
import SectionFeature from './SectionFeature';
import SectionMetrics from './SectionMetrics';
import SectionCentralize from './SectionCentralize';
import SectionAgent from './SectionAgent';
import SectionFooter from './SectionFooter';
import SectionEmployee from './SectionEmployee';
import SectionDataPrivacy from './SectionDataPrivacy'; // Import the new section

const Home: React.FC = () => {
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent('Page View', { page: 'Home' });
  }, [logEvent]);

  return (
    <div className="bg-[#1a1a2e]">
      <div>
        <HeroSection />
      </div>
      <SectionCentralize />
      <SectionAgent />
      <div id="features">
        <SectionFeature />
      </div>
      <SectionEmployee />
      <SectionMetrics />
      <SectionDataPrivacy />
      <SectionFooter />
    </div>
  );
};

export default Home;
