import React from 'react';
import { Link } from 'react-router-dom';
import AnimatedShinyText from './AnimatedShinyText';

interface CTAButtonProps {
  href: string;
  external?: boolean;
  text?: string;
  onClick?: () => void;
}

const CTAButton: React.FC<CTAButtonProps> = ({ href, external = false, text = "commencez à gagnez du temps", onClick }) => {
  const ButtonContent = (
    <button 
      onClick={onClick}
      className="px-8 py-3 text-base font-semibold uppercase tracking-wider bg-transparent border border-[#00ff9d] text-[#00ff9d] rounded-full transition-all duration-300 hover:bg-[rgba(0,255,157,0.1)] hover:shadow-[0_0_20px_rgba(0,255,157,0.3)] hover:scale-105"
    >
      <AnimatedShinyText>
        {text}
      </AnimatedShinyText>
    </button>
  );

  return external ? (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {ButtonContent}
    </a>
  ) : (
    <Link to={href}>
      {ButtonContent}
    </Link>
  );
};

export default CTAButton;