// pages/pricing-page.tsx
import React, { useEffect } from 'react';
import { useAmplitude } from './useAmplitude'; // Adjust the import path as necessary
import GetInTouch from '../components/GetInTouch';
import SectionFooter from './SectionFooter';

const GetInTouchPage: React.FC = () => {
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent('Page View', { page: 'Get In Touch' });
  }, [logEvent]);

  return (
    <div className="min-h-screen" style={{ background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)' }}>
      <div className="pt-16">
        <GetInTouch />
      </div>
      <SectionFooter />
    </div>
  );
};

export default GetInTouchPage;