"use client";

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from "./ui/button";
import { Switch } from "./ui/switch";
import { cn } from "../lib/utils";
import { CheckIcon } from "@radix-ui/react-icons";
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import WordFadeIn from "./WordFadeIn";

type Interval = "month" | "year";

export const toHumanPrice = (price: number, decimals: number = 2) => {
  return Number(price / 100).toFixed(decimals);
};

export function Pricing() {
  const [interval, setInterval] = useState<Interval>("month");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubscribeClick = () => {
    window.location.href = "https://apps.shopify.com/unanimityai?locale=fr";
  };

  const demoPrices = t('pricing.plans', { returnObjects: true }) as any[];

  return (
    <section className="relative overflow-hidden py-20" style={{ background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)' }}>
      <div className="absolute inset-0">
        <div className="absolute inset-0 opacity-30" style={{
          backgroundImage: `
            radial-gradient(circle at 50% 50%, rgba(0,255,157,0.1) 0%, transparent 50%),
            linear-gradient(rgba(0,255,157,0.05) 2px, transparent 2px),
            linear-gradient(90deg, rgba(0,255,157,0.05) 2px, transparent 2px)
          `,
          backgroundSize: 'cover, 30px 30px, 30px 30px',
          animation: 'grid-move 20s linear infinite'
        }} />
      </div>

      <div className="container relative mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <WordFadeIn 
            words={t('pricing.title')}
            className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]"
          />
          <p className="text-xl text-gray-300 mb-10">
            {t('pricing.subtitle')}
          </p>

          <div className="inline-flex items-center justify-center space-x-4 bg-[rgba(26,26,46,0.7)] backdrop-blur-md p-2 rounded-full border border-[rgba(0,255,157,0.2)]">
            <span className={`text-sm ${interval === 'month' ? 'text-[#00ff9d]' : 'text-gray-400'}`}>
              {t('pricing.monthly')}
            </span>
            <Switch
              id="interval"
              onCheckedChange={(checked) => setInterval(checked ? "year" : "month")}
              className="data-[state=checked]:bg-[#00ff9d]"
            />
            <span className={`text-sm ${interval === 'year' ? 'text-[#00ff9d]' : 'text-gray-400'}`}>
              {t('pricing.annual')}
            </span>
            <span className="ml-2 inline-block whitespace-nowrap rounded-full bg-[rgba(0,255,157,0.1)] border border-[#00ff9d] px-2.5 py-1 text-[11px] font-semibold uppercase tracking-wide text-[#00ff9d]">
              {t('pricing.annualDiscount')}
            </span>
          </div>
        </div>

        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {demoPrices.map((price, idx) => (
            <motion.div
              key={idx}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: idx * 0.1 }}
              className={cn(
                "relative group",
                {
                  "lg:scale-105 lg:z-10": idx === 1,
                }
              )}
            >
              <div className="absolute inset-0 bg-gradient-to-r from-[#00ff9d] to-[#00ffff] opacity-0 group-hover:opacity-20 blur-xl transition-opacity duration-500" />

              <div className={cn(
                "relative h-full bg-[rgba(26,26,46,0.7)] backdrop-blur-md p-8 rounded-xl border transition-all duration-300 hover:shadow-[0_0_30px_rgba(0,255,157,0.3)] hover:-translate-y-1",
                {
                  "border-[#00ff9d]": idx === 1,
                  "border-[rgba(0,255,157,0.2)]": idx !== 1,
                }
              )}>
                {idx === 1 && (
                  <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 px-4 py-1 bg-[#00ff9d] rounded-full text-[rgb(37,0,52)] text-sm font-bold">
                    {t('pricing.mostPopular')}
                  </div>
                )}

                <div className="mb-6">
                  <h2 className="text-2xl font-bold text-white mb-2">{price.name}</h2>
                  <p className="text-gray-400 h-12">{price.description}</p>
                </div>

                <div className="flex items-baseline mb-6">
                  <span className="text-5xl font-bold text-[#00ff9d] drop-shadow-[0_0_8px_rgba(0,255,157,0.3)]">
                    {price.name === t('pricing.plans.3.name')
                      ? t('pricing.customPricing')
                      : `$${interval === "year"
                        ? toHumanPrice(idx === 0 ? 95000 : idx === 1 ? 191000 : idx === 2 ? 383000 : 599000, 0)
                        : toHumanPrice(idx === 0 ? 9900 : idx === 1 ? 19900 : idx === 2 ? 39900 : 59900, 0)
                      }`}
                  </span>
                  {price.name !== t('pricing.plans.3.name') && (
                    <span className="ml-2 text-gray-400">
                      {t('pricing.perInterval', { interval: interval })}
                    </span>
                  )}
                </div>

                <Button
                  className={cn(
                    "w-full mb-8 transition-all duration-300",
                    idx === 1
                      ? "bg-[#00ff9d] text-[rgb(37,0,52)] hover:shadow-[0_0_20px_rgba(0,255,157,0.4)]"
                      : "bg-[rgba(0,255,157,0.1)] text-[#00ff9d] border border-[#00ff9d] hover:bg-[rgba(0,255,157,0.2)]"
                  )}
                  onClick={onSubscribeClick}
                >
                  {price.name === t('pricing.plans.3.name') ? t('pricing.contactUsButton') : t('pricing.subscribeButton')}
                </Button>

                <ul className="space-y-4">
                  {price.features.map((feature: string, idx: number) => (
                    <li key={idx} className="flex items-start space-x-3 text-gray-300">
                      <CheckIcon className="h-5 w-5 text-[#00ff9d] flex-shrink-0 mt-0.5" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      <style>{`
        @keyframes grid-move {
          0% { background-position: 0 0, 0 0, 0 0; }
          100% { background-position: 0 0, 30px 30px, 30px 30px; }
        }
      `}</style>
    </section>
  );
}