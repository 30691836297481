import React from 'react';
import { Link } from 'react-router-dom';
import ShimmerButton from './ShimmerButton'; 
import AnimatedShinyText from './AnimatedShinyText';
import CTAButton from './CTAButton';

const HeroSection: React.FC = () => {
  const scrollToFeatures = (e: React.MouseEvent) => {
    e.preventDefault();
    const featuresSection = document.getElementById('features');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section
      id="hero"
      className="relative overflow-hidden pt-24 pb-16 px-6"
      style={{ 
        minHeight: '90vh',
        background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)'
      }}
    >
      {/* Primary glow effect */}
      <div
        className="absolute inset-0 -z-10 blur-[120px] opacity-30"
        style={{
          background: 'radial-gradient(circle at 50% 50%, #00ff9d 0%, transparent 70%)'
        }}
      />

      {/* Secondary glow effects */}
      <div className="absolute -top-24 -left-24 w-96 h-96 bg-[#00ff9d] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />
      <div className="absolute -bottom-24 -right-24 w-96 h-96 bg-[#00ffff] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />

      <div className="container mx-auto flex flex-col items-center text-center">
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold leading-tight tracking-tight text-white mb-6 mt-6">
          Automatisez votre sav <br className="hidden lg:block" />
          <span className="text-[#00ff9d] drop-shadow-[0_0_10px_rgba(0,255,157,0.3)]">
            shopify
          </span>{' '}
          <span className="text-white">avec une IA qui agit vraiment</span>
        </h1>

        <p className="max-w-2xl text-gray-300 text-lg sm:text-xl mb-8 drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]">
          Unanimity ai gère vos retours, remboursements, étiquettes d'envoi et réponses clients.
        </p>

        <div className="flex flex-col gap-4 sm:flex-row sm:gap-6 justify-center">
          <CTAButton 
            href="https://apps.shopify.com/unanimityai?locale=fr"
            external={true}
          />

          <button 
            onClick={scrollToFeatures}
            className="px-8 py-3 text-base font-semibold uppercase tracking-wider text-white border border-white rounded-full transition-all duration-300 hover:border-[#00ffff] hover:text-[#00ffff] hover:bg-[rgba(0,255,255,0.1)] hover:shadow-[0_0_20px_rgba(0,255,255,0.3)] hover:scale-105"
          >
            en savoir plus
          </button>
        </div>

        <div className="relative mt-16 w-full max-w-4xl">
          {/* Dashboard image glow */}
          <div
            className="absolute inset-0 -z-10 blur-[40px]"
            style={{
              background: 'linear-gradient(180deg, rgba(0,255,157,0.2) 0%, transparent 100%)'
            }}
          />
          
          {/* Dashboard container */}
          <div className="relative bg-[rgba(26,26,46,0.7)] backdrop-blur-md p-2 rounded-xl border border-[rgba(0,255,157,0.2)] transition-all duration-300 hover:border-[#00ff9d] hover:shadow-[0_0_20px_rgba(0,255,157,0.3)]">
            <img
              src="/dashboard2.webp"
              alt="aperçu du tableau de bord unanimity ai"
              className="w-full h-auto rounded-lg"
            />
            
            {/* Decorative corner accents */}
            <div className="absolute top-0 left-0 w-16 h-16 border-t-2 border-l-2 border-[#00ff9d] rounded-tl-xl" />
            <div className="absolute top-0 right-0 w-16 h-16 border-t-2 border-r-2 border-[#00ff9d] rounded-tr-xl" />
            <div className="absolute bottom-0 left-0 w-16 h-16 border-b-2 border-l-2 border-[#00ff9d] rounded-bl-xl" />
            <div className="absolute bottom-0 right-0 w-16 h-16 border-b-2 border-r-2 border-[#00ff9d] rounded-br-xl" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
