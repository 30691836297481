import React, { useEffect } from 'react';
import { useAmplitude } from './useAmplitude';
import HeroSectionManaged from './HeroSectionManaged';
import SectionFooter from './SectionFooter';
// Import the new section

const Home: React.FC = () => {
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent('Page View', { page: 'Home' });
  }, [logEvent]);

  return (
    <div className="bg-[#1a1a2e]">
      <div>
        <HeroSectionManaged />
      </div>
      <SectionFooter />
    </div>
  );
};

export default Home;
