// pages/pricing-page.tsx
import React, { useEffect } from 'react';
import { useAmplitude } from './useAmplitude'; // Adjust the import path as necessary
import { PricingManaged } from './PricingManaged';
import SectionFooter from './SectionFooter';

const PricingPageManaged: React.FC = () => {
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent('Page View', { page: 'Pricing Managed' });
  }, [logEvent]);

  return (
    <div className="min-h-screen" style={{ background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)' }}>
      <div className="pt-24">
        <PricingManaged />
      </div>
      <SectionFooter />
    </div>
  );
};

export default PricingPageManaged;