import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Airtable from 'airtable';
import { BorderBeam } from "./BorderBeam";
import WordFadeIn from "./WordFadeIn";

const GetInTouch: React.FC = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [topic, setTopic] = useState(t('getInTouch.topicOptions.demoRequest'));
  const [description, setDescription] = useState(t('getInTouch.descriptionOptions.shopifyMerchant'));
  const [message, setMessage] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;
    if (!apiKey) {
      console.error('Airtable API key is not defined');
      return;
    }

    var base = new Airtable({ apiKey }).base('appXyv45a2Ukqh15X');

    base('unanimity').create([
      {
        "fields": {
          "First name": firstName,
          "Last name": lastName,
          "Email": email,
          "Topic": topic,
          "Description": description,
          "Message": message
        }
      }
    ], function (err, records) {
      if (err) {
        console.error(err);
        return;
      }
      if (records) {
        records.forEach(function (record) {
          console.log(record.getId());
        });
        alert(t('getInTouch.successMessage'));
        setFirstName('');
        setLastName('');
        setEmail('');
        setTopic(t('getInTouch.topicOptions.demoRequest'));
        setDescription(t('getInTouch.descriptionOptions.shopifyMerchant'));
        setMessage('');
      }
    });
  }

  return (
    <section className="relative overflow-hidden py-16">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <WordFadeIn 
          words={t('getInTouch.title')}
          className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-10 text-center drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]"
        />
        <div className="relative z-10 w-full max-w-6xl mx-auto bg-[rgba(26,26,46,0.7)] backdrop-blur-md rounded-lg border border-[rgba(0,255,157,0.2)] overflow-hidden transition-all duration-300 hover:border-[#00ff9d] hover:shadow-[0_0_20px_rgba(0,255,157,0.3)]">
          <BorderBeam />
          <div className="relative grid grid-cols-1 lg:grid-cols-5 gap-8 p-8">
            <div className="lg:col-span-3">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-300">{t('getInTouch.firstName')}</label>
                    <input
                      type="text"
                      id="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="mt-1 block w-full px-3 py-2 bg-[rgba(26,26,46,0.9)] border border-[rgba(0,255,157,0.2)] text-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#00ff9d] focus:border-[#00ff9d] transition-all duration-300"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-300">{t('getInTouch.lastName')}</label>
                    <input
                      type="text"
                      id="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="mt-1 block w-full px-3 py-2 bg-[rgba(26,26,46,0.9)] border border-[rgba(0,255,157,0.2)] text-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#00ff9d] focus:border-[#00ff9d] transition-all duration-300"
                      required
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-300">{t('getInTouch.email')}</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-[rgba(26,26,46,0.9)] border border-[rgba(0,255,157,0.2)] text-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#00ff9d] focus:border-[#00ff9d] transition-all duration-300"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="topic" className="block text-sm font-medium text-gray-300">{t('getInTouch.topic')}</label>
                  <select
                    id="topic"
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-[rgba(26,26,46,0.9)] border border-[rgba(0,255,157,0.2)] text-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#00ff9d] focus:border-[#00ff9d] transition-all duration-300"
                    required
                  >
                    {Object.entries(t('getInTouch.topicOptions', { returnObjects: true })).map(([key, value]) => (
                      <option key={key} value={value as string} className="bg-[rgba(26,26,46,0.9)]">{value as string}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label htmlFor="description" className="block text-sm font-medium text-gray-300">{t('getInTouch.description')}</label>
                  <select
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-[rgba(26,26,46,0.9)] border border-[rgba(0,255,157,0.2)] text-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#00ff9d] focus:border-[#00ff9d] transition-all duration-300"
                    required
                  >
                    {Object.entries(t('getInTouch.descriptionOptions', { returnObjects: true })).map(([key, value]) => (
                      <option key={key} value={value as string} className="bg-[rgba(26,26,46,0.9)]">{value as string}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-300">{t('getInTouch.message')}</label>
                  <textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-[rgba(26,26,46,0.9)] border border-[rgba(0,255,157,0.2)] text-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#00ff9d] focus:border-[#00ff9d] transition-all duration-300"
                    rows={4}
                    required
                  />
                </div>

                <div className="flex justify-center">
                  <button 
                    type="submit" 
                    className="w-full bg-transparent border border-[#00ff9d] text-[#00ff9d] font-bold py-3 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-[#00ff9d] transition duration-300 ease-in-out hover:bg-[rgba(0,255,157,0.1)] hover:shadow-[0_0_20px_rgba(0,255,157,0.3)] hover:scale-105"
                  >
                    {t('getInTouch.submit')}
                  </button>
                </div>
              </form>
            </div>

            <div className="lg:col-span-2 text-white">
              <h2 className="text-2xl font-bold mb-4 text-[#00ff9d] drop-shadow-[0_0_8px_rgba(0,255,157,0.3)]">
                {t('getInTouch.contactUs')}
              </h2>
              <p className="mb-4 text-gray-300">
                {t('getInTouch.contactDescription1')}
              </p>
              <p className="mb-4 text-gray-300">
                {t('getInTouch.contactDescription2')}
              </p>
              <p className="font-bold text-[#00ff9d] drop-shadow-[0_0_8px_rgba(0,255,157,0.3)]">
                {t('getInTouch.contactEmail')}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Background glow effect */}
      <div 
        className="absolute inset-0 -z-10 opacity-30"
        style={{
          background: 'radial-gradient(circle at 50% 50%, #00ff9d 0%, transparent 70%)'
        }}
      />
    </section>
  );
}

export default GetInTouch;