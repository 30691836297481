import React from 'react';
import LegalNotice from './LegalNotice';
import SectionFooter from './SectionFooter';

const LegalNoticePage: React.FC = () => {
  return (
    <div className="min-h-screen" style={{ background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)' }}>
      <div className="pt-24 pb-16 px-4">
        <LegalNotice />
      </div>
      <SectionFooter />
    </div>
  );
};

export default LegalNoticePage;