import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LanguageSwitcher from './LanguageSwitcher';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import CTAButton from './CTAButton';

interface NavMenuProps {
  links: { href: string; label: string }[];
  hideNav?: boolean;
}

const NavMenu: React.FC<NavMenuProps> = ({ links, hideNav }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isManaged = location.pathname === '/managed';
  const isPricingManaged = location.pathname === '/managed-pricing';
  const isGetInTouch = location.pathname === '/get-in-touch';

  const handleLinkClick = (href: string) => {
    setIsOpen(false);
    navigate(href);
  };

  const regularLinks = links.filter(link => {
    if (isPricingManaged || isGetInTouch) {
      return false;
    }
    return link.href !== '/get-in-touch';
  });
  const ctaLink = links.find(link => link.href === '/get-in-touch');

  return (
    <div>
      {!hideNav && (
        <>
          {/* Desktop Menu */}
          <nav className="hidden md:flex items-center space-x-6">
            {regularLinks.map((link, index) => (
              <button
                key={index}
                onClick={() => handleLinkClick(link.href)}
                className="text-white hover:text-[#00ff9d] transition-colors duration-300 font-medium tracking-wide"
              >
                {link.label}
              </button>
            ))}
            {!isPricingManaged && !isGetInTouch && (
              <>
                <div className="h-6 w-px bg-[rgba(0,255,157,0.3)]" /> {/* Separator */}
                {ctaLink && (
                  <button
                    onClick={() => handleLinkClick(ctaLink.href)}
                    className="text-[#00ff9d] hover:text-[#00ffff] transition-colors duration-300 font-medium tracking-wide"
                  >
                    {ctaLink.label}
                  </button>
                )}
                {!isManaged && (
                  <CTAButton 
                    href="https://apps.shopify.com/unanimityai?locale=fr"
                    external={true}
                    text="installer l'app"
                  />
                )}
              </>
            )}
          </nav>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button 
              onClick={() => setIsOpen(!isOpen)} 
              className="p-2 text-white hover:text-[#00ff9d] transition-colors duration-300"
            >
              {isOpen ? (
                <XMarkIcon className="h-6 w-6" />
              ) : (
                <Bars3Icon className="h-6 w-6" />
              )}
            </button>

            {/* Mobile Menu Dropdown */}
            {isOpen && (
              <div className="absolute top-16 right-4 w-64 py-2 bg-[rgba(26,26,46,0.95)] backdrop-blur-lg rounded-lg border border-[rgba(0,255,157,0.2)] shadow-[0_0_20px_rgba(0,255,157,0.2)]">
                {regularLinks.map((link, index) => (
                  <button
                    key={index}
                    onClick={() => handleLinkClick(link.href)}
                    className="block w-full text-left px-4 py-2 text-white hover:bg-[rgba(0,255,157,0.1)] transition-colors duration-300"
                  >
                    {link.label}
                  </button>
                ))}
                {!isManaged && !isGetInTouch && (
                  <div className="px-4 py-2 mt-2">
                    <CTAButton 
                      href="https://apps.shopify.com/unanimityai?locale=fr"
                      external={true}
                      text="installer l'app"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default NavMenu;