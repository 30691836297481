import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HeroSectionManaged: React.FC = () => {
  const { t } = useTranslation();

  const scrollToFeatures = (e: React.MouseEvent) => {
    e.preventDefault();
    const featuresSection = document.getElementById('features');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section
      id="hero"
      className="relative overflow-hidden pt-24 pb-16 px-6"
      style={{
        minHeight: '90vh',
        background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)'
      }}
    >
      {/* Primary glow effect */}
      <div
        className="absolute inset-0 -z-10 blur-[120px] opacity-30"
        style={{
          background: 'radial-gradient(circle at 50% 50%, #00ff9d 0%, transparent 70%)'
        }}
      />

      {/* Secondary glow effects */}
      <div className="absolute -top-24 -left-24 w-96 h-96 bg-[#00ff9d] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />
      <div className="absolute -bottom-24 -right-24 w-96 h-96 bg-[#00ffff] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />

      <div className="container mx-auto flex flex-col items-center text-center">
        {/* MAIN HEADLINE */}
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold leading-tight tracking-tight text-white mb-6 mt-6">
          {t('heroManaged.headline.first')} <br className="hidden lg:block" />
          <span className="text-[#00ff9d]">{t('heroManaged.headline.second')}</span>
        </h1>

        {/* SUB-HEADLINE */}
        <p className="max-w-2xl text-gray-300 text-xl sm:text-2xl mb-8 drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]">
          {t('heroManaged.subheadline')}
        </p>

        {/* TWO CTAS */}
        <div className="flex flex-col gap-4 sm:flex-row sm:gap-6 justify-center">
          <Link
            to="/diy"
            className="inline-block px-8 py-3 text-base font-semibold uppercase tracking-wider text-white bg-[rgba(0,255,157,0.1)] border border-[#00ff9d] rounded-full transition-all duration-300 hover:bg-[#00ff9d] hover:text-[#1a1a2e] hover:shadow-[0_0_20px_rgba(0,255,157,0.4)] hover:scale-105"
          >
            {t('heroManaged.cta.diy')}
          </Link>

          <Link
            to="/managed"
            className="inline-block px-8 py-3 text-base font-semibold uppercase tracking-wider text-white bg-[rgba(0,255,255,0.1)] border border-[#00ffff] rounded-full transition-all duration-300 hover:bg-[#00ffff] hover:text-[#1a1a2e] hover:shadow-[0_0_20px_rgba(0,255,255,0.4)] hover:scale-105"
          >
            {t('heroManaged.cta.managed')}
          </Link>
        </div>


        {/* DASHBOARD PREVIEW */}
        <div className="relative mt-16 w-full max-w-4xl">
          {/* Glow behind the image */}
          <div
            className="absolute inset-0 -z-10 blur-[40px]"
            style={{
              background:
                'linear-gradient(180deg, rgba(0,255,157,0.2) 0%, transparent 100%)'
            }}
          />
          
          <div className="relative bg-[rgba(26,26,46,0.7)] backdrop-blur-md p-2 rounded-xl border border-[rgba(0,255,157,0.2)] transition-all duration-300 hover:border-[#00ff9d] hover:shadow-[0_0_20px_rgba(0,255,157,0.3)]">
            <img
              src="/dashboard2.webp"
              alt="Preview of Unanimity AI dashboard"
              className="w-full h-auto rounded-lg"
            />

            {/* Decorative corners */}
            <div className="absolute top-0 left-0 w-16 h-16 border-t-2 border-l-2 border-[#00ff9d] rounded-tl-xl" />
            <div className="absolute top-0 right-0 w-16 h-16 border-t-2 border-r-2 border-[#00ff9d] rounded-tr-xl" />
            <div className="absolute bottom-0 left-0 w-16 h-16 border-b-2 border-l-2 border-[#00ff9d] rounded-bl-xl" />
            <div className="absolute bottom-0 right-0 w-16 h-16 border-b-2 border-r-2 border-[#00ff9d] rounded-br-xl" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSectionManaged;
