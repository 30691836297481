import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DocumentCheckIcon } from '@heroicons/react/24/outline';

const Terms: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="relative max-w-4xl mx-auto p-8 bg-[rgba(26,26,46,0.7)] backdrop-blur-md text-white rounded-xl border border-[rgba(0,255,157,0.2)]">
      {/* Background Effects */}
      <div className="absolute -top-24 -left-24 w-96 h-96 bg-[#00ff9d] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />
      <div className="absolute -bottom-24 -right-24 w-96 h-96 bg-[#00ffff] rounded-full mix-blend-multiply filter blur-[128px] opacity-20" />
      
      {/* Grid Background */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0" style={{
          backgroundImage: `
            linear-gradient(rgba(0,255,157,0.05) 1px, transparent 1px),
            linear-gradient(90deg, rgba(0,255,157,0.05) 1px, transparent 1px)
          `,
          backgroundSize: '20px 20px'
        }} />
      </div>

      {/* Logo Section */}
      <div className="relative flex flex-col items-center mb-12">
        <Link to="/" className="block relative">
          <div className="w-32 h-32 rounded-full bg-[rgba(0,255,157,0.1)] p-4 border border-[#00ff9d] shadow-[0_0_30px_rgba(0,255,157,0.3)] mb-8 transition-all duration-300 hover:shadow-[0_0_40px_rgba(0,255,157,0.4)] hover:scale-105">
            <img src="/logo192.png" alt="Unanimityai logo" className="w-full h-full" />
          </div>
        </Link>
        <DocumentCheckIcon className="absolute -top-4 -right-4 w-12 h-12 text-[#00ff9d] drop-shadow-[0_0_8px_rgba(0,255,157,0.3)]" />
      </div>

      {/* Title Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4 text-white drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]">
          {t('terms.title')}
        </h1>
        <p className="text-[#00ff9d] font-medium">
          <strong>{t('terms.lastUpdated')}</strong>
        </p>
      </div>

      {/* Content */}
      <div className="space-y-8">
        <div className="bg-[rgba(26,26,46,0.5)] p-6 rounded-lg border border-[rgba(0,255,157,0.1)] hover:border-[#00ff9d] transition-colors duration-300">
          <p className="text-gray-300 leading-relaxed">{t('terms.introduction')}</p>
        </div>

        {[
          'intellectualProperty',
          'thirdPartyLinks',
          'limitationOfLiability',
          'modificationOfTerms',
          'applicableLaw'
        ].map((section) => (
          <div key={section} className="bg-[rgba(26,26,46,0.5)] p-6 rounded-lg border border-[rgba(0,255,157,0.1)] hover:border-[#00ff9d] transition-colors duration-300">
            <h2 className="text-2xl font-bold mb-4 text-white drop-shadow-[0_0_8px_rgba(255,255,255,0.2)]">
              {t(`terms.${section}.title`)}
            </h2>
            <p className="text-gray-300 leading-relaxed">
              {t(`terms.${section}.content`)}
            </p>
          </div>
        ))}

        {/* Contact Information */}
        <address className="not-italic bg-[rgba(26,26,46,0.5)] p-6 rounded-lg border border-[rgba(0,255,157,0.1)] text-gray-300">
          Unanimity AI<br />
          Cagnes sur mer, 06800 France<br />
          <a href="mailto:contact@unanimity.ai" className="text-[#00ff9d] hover:text-[#00ffff] transition-colors duration-300">
            contact@unanimity.ai
          </a>
        </address>

        {/* Agreement */}
        <p className="text-[#00ff9d] font-bold text-center">{t('terms.agreement')}</p>
      </div>
    </div>
  );
};

export default Terms;